import React from 'react';
import {CardInfo, CardInfoFlag} from '@components/UI/Card/CardInfo';
import {CardRow, CardRowType} from '@components/UI/Card/CardRow';
import {CardSubTitle} from '@components/UI/Card/CardSubTitle';
import {CardInfosWrapper} from '@components/UI/Card/CardInfosWrapper';
import {Card} from '@components/UI/Card/Card';
import {CardTitleContainer} from '@components/UI/Card/CardTitleContainer';
import {CardTitle} from '@components/UI/Card/CardTitle';
import {formatDate} from '@utils/string';
import OrdersStatusTag, {OrdersStatusEnum} from '@components/Orders/OrdersStatusTag';
import {formatNumber} from '@utils/number';
import {isDef} from '@lepicard/utils';
import {CardSeparator} from '@components/UI/Card/CardSeparator';
import {Accordion} from '@components/UI/Accordion/Accordion';
import {IApproDeliveriesRow} from '@components/ApproDeliveries/ApproDeliveriesRows/ApproDeliveriesRows';

interface IDeliverySubRow {
  product: any;
  onClick: () => void;
  toggleIcon: React.ReactNode;
}

const SubRow: React.FC<IDeliverySubRow> = ({product, onClick, toggleIcon}) => {
  const totalItems = product.deliveries.length + product.returnDeliveries.length;
  const showMoreLabel = `${totalItems} ${totalItems > 1 ? 'livraisons' : 'livraison'}`;
  return (
    <CardRow type={CardRowType.SUB_PREVIEW} onClick={onClick}>
      <div className="flex justify-between gap-1">
        <CardSubTitle title={product.description} />
        <div className="flex gap-2 items-center shrink-0">
          <p>{showMoreLabel}</p>
          {toggleIcon}
        </div>
      </div>
    </CardRow>
  );
};

interface IDeliverySubSubRow {
  delivery: any;
}

const SubSubRow: React.FC<IDeliverySubSubRow> = ({delivery}) => {
  const quantityLabel = formatNumber(delivery.quantity as number)
    .round()
    .renderWithUnit(delivery.unitOfMeasureCode);

  return (
    <CardRow type={CardRowType.SUB}>
      <CardTitleContainer>
        <CardSubTitle title={`LIVRAISON ${delivery.no}`} />
        {isDef(delivery.letter) && (
          <div className="Card-tags">
            <OrdersStatusTag status={OrdersStatusEnum.BILLED} />
          </div>
        )}
      </CardTitleContainer>
      <CardSeparator />
      <CardInfosWrapper>
        <CardInfo label="Commande lié" value={delivery.linkedOrder} />
        {isDef(delivery.linkedContract) && <CardInfo label="Contrat lié" value={delivery.linkedContract} />}
        <CardSeparator className="lg:hidden" />
        <CardInfo label="Date de livraison" value={formatDate(delivery.shipmentDate)} />
        <CardSeparator className="lg:hidden" />
        <CardInfo
          label="Quantité"
          value={quantityLabel}
          flags={[CardInfoFlag.QUANTITY, CardInfoFlag.IMPORTANT, CardInfoFlag.END_OF_LINE]}
        />
      </CardInfosWrapper>
    </CardRow>
  );
};

interface IDeliverySubSubRowReturn {
  singleReturn: any;
}

const SubSubRowReturn: React.FC<IDeliverySubSubRowReturn> = ({singleReturn}) => {
  const quantityLabel = formatNumber(singleReturn.quantity as number)
    .round()
    .negate()
    .renderWithUnit(singleReturn.unitOfMeasureCode);

  return (
    <CardRow type={CardRowType.SUB}>
      <CardTitleContainer>
        <CardSubTitle title={`RETOUR ${singleReturn.rv}`} />
        <div className="Card-tags">
          <OrdersStatusTag status={OrdersStatusEnum.RETURNED} />
        </div>
      </CardTitleContainer>
      <CardSeparator />
      <CardInfosWrapper>
        <CardInfo label="Commande lié" value={singleReturn.cmdId} flags={CardInfoFlag.RETURN} />
        {isDef(singleReturn.linkedContract) && (
          <CardInfo label="Contrat lié" value={singleReturn.linkedContract} flags={CardInfoFlag.RETURN} />
        )}
        <CardSeparator className="lg:hidden" />
        <CardInfo label="Date de retour" value={formatDate(singleReturn.returnDate)} flags={CardInfoFlag.RETURN} />
        <CardSeparator className="lg:hidden" />
        <CardInfo
          label="Quantité"
          value={quantityLabel}
          flags={[CardInfoFlag.QUANTITY, CardInfoFlag.IMPORTANT, CardInfoFlag.END_OF_LINE, CardInfoFlag.RETURN]}
        />
      </CardInfosWrapper>
    </CardRow>
  );
};

export const ApproDeliveriesRowByFamily: React.FC<IApproDeliveriesRow> = ({data}: any) => {
  const subRows = (
    <>
      {data.products.map((product: any) => (
        <Accordion
          key={product.description}
          head={({onClick, toggleIcon}) => <SubRow product={product} onClick={onClick} toggleIcon={toggleIcon} />}
        >
          {product.deliveries.map((d: any) => (
            <SubSubRow delivery={d} key={d.no} />
          ))}
          {product.returnDeliveries.map((returnDelivery: any, key: number) => (
            <SubSubRowReturn singleReturn={returnDelivery} key={key} />
          ))}
        </Accordion>
      ))}
    </>
  );

  return (
    <Card>
      <CardRow>
        <CardTitleContainer>
          <CardTitle title={data.familyName} />
        </CardTitleContainer>
      </CardRow>
      {subRows}
    </Card>
  );
};
