import {types, onSnapshot, flow} from 'mobx-state-tree';
import {fetchOffers} from '@services/offers';
import {OffersStoreModel} from './models';
import views from './views';
import {errorHasMessageStringKey} from '@stores/durability/store';

export const OffersStore = types.optional(
  OffersStoreModel.views(views).actions((self) => {
    return {
      // flow : asynchronous actions
      fetchOffers: flow(function* (filters: any) {
        const serviceState = self.fetchOffersState;
        serviceState.pending();
        try {
          const result = yield fetchOffers(filters);

          self.list = result[0];
          serviceState.done();
        } catch (error) {
          console.log('error', error);
          serviceState.fail({sendToast: false, message: errorHasMessageStringKey(error) ? error.message : undefined, error});
        }
      }),
    };
  }),
  {}
);
