import React, {useMemo, useState, useEffect, createContext} from 'react';
import {observer} from 'mobx-react-lite';
import {useStore} from '@stores/index';
import {fetchApproInvoices, IApproInvoicesFilter} from '@services/navagri';
import {getNoDataExerciceMessage} from '@utils/tables';
import {isUndefined} from '@lepicard/utils';
import Loader from '@components/Loader';
import usePagination from '@hooks/usePagination';
import {useRerenderSafeDebounce} from '@hooks/useRerenderSafeDebounce';
import {Formik} from 'formik';
import NoData from '@components/NoData/NoData';
import Pagination from '@components/Pagination';
import {
  ApproInvoicesListFilters,
  groupByOption,
} from '@components/ApproInvoices/ApproInvoicesFilters/ApproInvoicesFilters';
import {ApproInvoicesRows} from '@components/ApproInvoices/ApproInvoicesRows/ApproInvoicesRows';
import usePdfGenerator from '@hooks/usePdfGenerator';
import {format} from 'date-fns';
import {csvColumns, headerValue, pdfCol, pdfSubCol} from './column';
import useCsvGenerator from '@hooks/useCsvGenerator';

interface IApproInvoicesListFormContext {
  formValues: any;
  refreshFormValues: (newValues: any) => void;
  onDownloadPdf: () => void;
  onDownloadCsv: () => void;
}

export const ApproInvoicesListFormContext = createContext<IApproInvoicesListFormContext>({
  formValues: {},
  refreshFormValues: () => {},
  onDownloadPdf: () => {},
  onDownloadCsv: () => {},
});

const ApproInvoicesList: React.FC = observer(() => {
  const {approInvoicesStore, campaigns: campaignsStore} = useStore();

  const {currentCampaign} = campaignsStore;

  const list = approInvoicesStore.list.toJSON();

  const initialFormValues = {
    campaignId: approInvoicesStore.getCampaignsOptions.some((option) => option.value === currentCampaign!.id)
      ? currentCampaign!.id
      : 'all',
    groupBy: 'invoice',
    status: [],
    itemFamilyCode: [],
    search: '',
  };

  const [formValues, setFormValues] = useState(initialFormValues);

  const filters = useMemo<IApproInvoicesFilter>(() => {
    const newFilters: IApproInvoicesFilter = {};

    if (formValues.campaignId !== 'all') {
      newFilters.campaignId = formValues.campaignId;
    }

    newFilters.groupBy = formValues.groupBy;

    if (formValues.search !== '') {
      newFilters.search = formValues.search;
    }

    if (formValues.status.length >= 0) {
      newFilters.status = formValues.status;
    }

    if (formValues.itemFamilyCode.length >= 0) {
      newFilters.familyCode = formValues.itemFamilyCode;
    }

    return newFilters;
  }, [formValues]);

  const loadDataToExport = async () => {
    const {data} = await fetchApproInvoices({$limit: Number.MAX_SAFE_INTEGER, ...filters, $skip: 0});
    return data;
  };

  const {isPending: isPdfPending, download: downloadPdf} = usePdfGenerator(pdfCol, pdfSubCol);
  const {isPending: isCsvPending, download: downloadCsv} = useCsvGenerator(csvColumns);

  const onDownloadPdf = () => {
    downloadPdf({
      loadDatas: loadDataToExport,
      title: `APPRO. - Mes Factures`,
      fileName: `Appro_factures_${format(new Date(), `dd/MM/yyyy`)}`,
      pdfType: 'Appro',
      headerValue: headerValue(formValues, groupByOption, approInvoicesStore),
      subtitle: 'approInvoices',
    });
  };

  const onDownloadCsv = () => {
    downloadCsv({
      loadDatas: loadDataToExport,
      fileName: `Export_Appro_factures_${formValues.campaignId}`,
      headerValue: headerValue(formValues, groupByOption, approInvoicesStore),
    });
  };

  const loadData = (page: number) => {
    approInvoicesStore.fetchInvoices(page, filters);
  };

  const {pageNumber, onPageChange, updatePagination} = usePagination(loadData);

  const fetchInvoicesDebounced = useRerenderSafeDebounce(approInvoicesStore.fetchInvoices, 500);
  useEffect(() => {
    fetchInvoicesDebounced(1, filters);
    updatePagination(1);
  }, [filters]);

  const displayLoader = ['initial', 'error', 'pending'].includes(approInvoicesStore.fetchInvoicesState.state);

  return (
    <ApproInvoicesListFormContext.Provider
      value={{
        formValues: formValues,
        refreshFormValues: (newValues) => setFormValues(newValues),
        onDownloadPdf,
        onDownloadCsv,
      }}
    >
      <div>
        <Formik initialValues={formValues} onSubmit={() => {}}>
          <ApproInvoicesListFilters />
        </Formik>

        {displayLoader ? (
          <Loader topMargin />
        ) : (
          <>
            <ApproInvoicesRows />

            {(Array.isArray(list) && list.length === 0) || !Array.isArray(list) ? (
              <NoData message={getNoDataExerciceMessage(filters, currentCampaign?.id)} />
            ) : (
              <Pagination
                activePage={pageNumber}
                itemsCountPerPage={10}
                totalItemsCount={approInvoicesStore.fetchInvoicesState.total ?? 0}
                onChange={onPageChange}
              />
            )}
          </>
        )}
      </div>
    </ApproInvoicesListFormContext.Provider>
  );
});

const ApproInvoicesListContainer: React.FC = observer(() => {
  const {campaigns: campaignsStore, approInvoicesStore} = useStore();

  const areCampaignsOptionsLoaded = ['done', 'error'].includes(
    approInvoicesStore.fetchInvoicesGlobalFiltersOptionsState.state
  );

  useEffect(() => {
    if (!areCampaignsOptionsLoaded) {
      approInvoicesStore.fetchInvoicesGlobalFiltersOptions();
    }
  }, []);

  if (isUndefined(campaignsStore.currentCampaign?.id) || !areCampaignsOptionsLoaded) {
    return <Loader topMargin />;
  }

  return <ApproInvoicesList />;
});

export default ApproInvoicesListContainer;
