import React, {useState, useEffect, Fragment, useMemo} from 'react';
import {observer} from 'mobx-react-lite';
import {useStore} from '@stores/index';
import useModal from '@hooks/useModal';
import {fetchCollectDeliveries, ICollectDeliveriesFilters} from '@services/navagri';
import {getNoDataMessage} from '@utils/tables';
import FiltersCard from '@components/UI/FiltersCard/FiltersCard';
import Select from '@components/NewFields/Select';
import usePdfGenerator from '@hooks/usePdfGenerator';
import useCsvGenerator from '@hooks/useCsvGenerator';
import useEffectNotOnFirstRender from '@hooks/useEffectNotOnFirstRender';
import Button from '@components/UI/Button/Button';
import {StyleSheet, Text, View} from '@react-pdf/renderer';
import {numberToLocaleString} from '@utils/string';
import useDownloadPdf from '@hooks/useDownloadPdf';
import Loader from '@components/Loader';
import CheckBox from '@components/NewFields/CheckBox';
import OptionModal from '@components/UI/OptionModal/OptionModal';
import {Form, Formik, FormikProps, FormikValues, useFormikContext} from 'formik';
import Tag from '@components/UI/Tag/Tag';
import TagsContainer from '@components/UI/TagsContainer/TagsContainer';
import Input from '@components/NewFields/Input';
import QuickHelp from '@components/UI/QuickHelp/QuickHelp';
import Pagination from '@components/Pagination';
import usePagination from '@hooks/usePagination';
import ExportButtons from '@components/UI/ExportButtons/ExportButtons';
import DeliveryRow, {NORME_TYPE_LABEL_MAPPING, normeToLowercase} from '../DeliveryRow/DeliveryRow';
import {cn} from '@utils/class-names';
import {isNull, isUndefined} from '@lepicard/utils';
import NoData from '@components/NoData/NoData';
import Search from '@components/Icons/Search';
import CheckBoxAllSelector from '@components/NewFields/CheckBoxAllSelector';
import usePrevious from '@hooks/usePrevious';
import {format} from 'date-fns';
import {csvColumns, getCsvFileName, getFormatedQuantity, headerValues, pdfColumns} from './column';
import {convertContractTypeOptions} from '@utils/contracts/contractType';

type IHandleFormRewrite = {
  specieAsOptions: any[];
  subFamilyAsOptions: any[];
};

const HandleFormRewrite: React.FC<IHandleFormRewrite> = observer(({specieAsOptions, subFamilyAsOptions}) => {
  const {values, setFieldValue} = useFormikContext<Record<string, any>>();

  const previousValues = usePrevious(values);

  useEffect(() => {
    // Ignore first render
    if (isUndefined(previousValues)) {
      return;
    }

    const species = specieAsOptions.filter((specie) => values.speciesId.includes(specie.specie));
    if (
      values.speciesId &&
      values.speciesId !== 'all' &&
      species.findIndex((specie) => specie.value === values.itemId) === -1
    ) {
      delete values.itemId;
    }

    // If "Campagne" filter value changes, reset "Variété" (`species`) and "Culture" (`itemId`) filter values.
    if (values.campaignId !== previousValues.campaignId) {
      if (values.campaignId === 'all' && previousValues.campaignId === undefined) {
        // unintended/auto filter
      } else {
        values.itemId = 'all';
        values.speciesId = 'all';
      }
    }

    // If "Culture" filter value changes, reset the "Variété" (`species`) filter value.
    if (values.speciesId !== previousValues.speciesId) {
      if (values.speciesId === 'all' && previousValues.speciesId === undefined) {
        // don't filter intentionally
      } else {
        values.itemId = 'all';
      }
    }

    // If "Variété" filter value changes, adapt "Culture" filter value according to "Variété" new filter value.
    if (values.itemId !== previousValues.itemId && values.itemId != 'all') {
      if (values.itemId === undefined) {
        values.itemId = 'all';
      } else {
        values.speciesId = subFamilyAsOptions.find(
          (specie: {value: any}) => specie.value === values.itemId.slice(0, 2)
        ).value;
      }
    }

    // Reset contractType when global filters changes
    const params: string[] = ['campaignId', 'speciesId', 'itemId'];
    params.forEach((param) => {
      if (values[param] !== previousValues?.[param]) {
        if (values[param] !== 'all') {
          if (values.contractType.length > 0) {
            values.contractType = [];
          }
        }
      }
    });
  }, [values]);
  return null;
});

const DeliveriesListWrapper: React.FC = observer(() => {
  const {collectDeliveries: collectDeliveriesStore, campaigns: campaignsStore} = useStore();
  const {currentCampaign} = campaignsStore;

  const currentCampaignId = currentCampaign!.id;

  const {isPending: isPdfPending, download: downloadPdf} = usePdfGenerator(pdfColumns);
  const {isPending: isCsvPending, download: downloadCsv} = useCsvGenerator(csvColumns);
  const {isPending: isPending, download: download} = useDownloadPdf();

  const tagsOptions = [
    {label: 'Contrats non facturés', value: 'isNotFinished'},
    {label: 'Règlements à venir', value: 'nextInvoice'},
  ];

  const defaultValues: FormikValues = {
    campaignId: collectDeliveriesStore.getCollectCampaigns.some((option) => option.value === currentCampaignId)
      ? currentCampaign!.id
      : 'all',
    contractType: [],
    speciesId: 'all',
    itemId: 'all',
    search: '',
  };

  const [formValues, setFormValues] = useState(defaultValues);

  const filters = useMemo<ICollectDeliveriesFilters>(() => {
    if (isNull(formValues)) {
      return {campaignId: currentCampaignId};
    }

    const newFilters: ICollectDeliveriesFilters = {};

    if (formValues.campaignId !== 'all') {
      newFilters.campaignId = formValues.campaignId;
    }

    if (formValues.speciesId !== 'all') {
      newFilters.speciesId = formValues.speciesId;
    }

    if (formValues.itemId !== 'all') {
      newFilters.itemId = formValues.itemId;
    }

    if (formValues.search !== '') {
      newFilters.search = formValues.search;
    }

    if (formValues.contractType.length > 0) {
      newFilters.contractType = formValues.contractType;
    }

    if (formValues.tagsOption !== 'all' && currentCampaignId === formValues.campaignId) {
      newFilters.tagsOption = formValues.tagsOption;
    }

    return newFilters;
  }, [formValues]);

  const loadData = (page: number) => {
    collectDeliveriesStore.fetchCollectDeliveries(page, filters);
  };

  const loadDataToExport = async () => {
    const {data} = await fetchCollectDeliveries({...filters, $limit: Number.MAX_SAFE_INTEGER});
    return data;
  };

  useEffect(() => {
    collectDeliveriesStore.fetchSpeciesDeliveries({campaignId: filters.campaignId});
  }, [filters.campaignId]);

  useEffect(() => {
    onPageChange();
  }, [filters]);

  const list = collectDeliveriesStore.list ? collectDeliveriesStore.list.toJSON() : [];

  const {active, toggle} = useModal();

  const onDownloadPdf = () => {
    downloadPdf({
      loadDatas: loadDataToExport,
      title: 'COLLECTE - MES LIVRAISONS',
      fileName: `collecteLivraisons_${format(new Date(), `dd/MM/yyyy`)}`,
      pdfType: 'Collecte',
      headerValue: headerValues(formValues, collectDeliveriesStore, contractTypeOptions),
      subComponent: (data) => subComponent(data),
    });
  };

  const subComponent = (data: {collects_analysis: any[]}) => {
    const styles = StyleSheet.create({
      analyse: {
        flexDirection: 'row',
        alignItems: 'center',
        paddingRight: 20,
      },
    });

    return (
      <Fragment>
        {data.collects_analysis
          .filter((analyse) => analyse.analyseValue !== 0)
          .map((analyse, index) => (
            <View key={index} style={styles.analyse}>
              <Text style={{color: '#728094'}}>
                {`${NORME_TYPE_LABEL_MAPPING[analyse.analyseCode]?.name || normeToLowercase(analyse.analyseCode)} : `}
              </Text>
              <Text style={{color: 'black'}}>{`${numberToLocaleString(analyse.analyseValue)} %     `}</Text>
            </View>
          ))}
      </Fragment>
    );
  };

  const onDownloadCsv = () => {
    downloadCsv({
      loadDatas: loadDataToExport,
      fileName: `Export_Collecte_Livraisons_${getCsvFileName(formValues, tagsOptions, collectDeliveriesStore)}`,
      headerValue: headerValues(formValues, collectDeliveriesStore, contractTypeOptions),
    });
  };

  const getDeliverySummaryValueClasses = (value: number | null | undefined) => {
    const isNullClass = value === 0 || isNull(value) || isUndefined(value);
    return cn('DeliverySummary-value', {isNull: isNullClass});
  };

  const {pageNumber, onPageChange} = usePagination(loadData);

  const getFilteredItemOptions = () => {
    const result =
      formValues && formValues.speciesId !== 'all'
        ? collectDeliveriesStore.specieAsOptions.filter((specie) => formValues.speciesId.includes(specie.specie))
        : collectDeliveriesStore.specieAsOptions;
    return [...result];
  };

  const contractTypeOptions = convertContractTypeOptions(collectDeliveriesStore.facets?.contractTypes.toJSON() ?? []);

  return (
    <>
      {isPending && (
        <div>
          <Loader className="fullScreenLoader" />
        </div>
      )}
      <div>
        <Formik
          initialValues={defaultValues}
          onSubmit={(values) => console.log('submit', values)}
          children={({values, setFieldValue}: FormikProps<any>) => {
            useEffectNotOnFirstRender(() => {
              setFormValues(values);
            }, [values]);

            return (
              <Form className="w-full mb-5">
                <HandleFormRewrite
                  specieAsOptions={collectDeliveriesStore.specieAsOptions}
                  subFamilyAsOptions={collectDeliveriesStore.subFamilyAsOptions}
                />
                <FiltersCard className="grid grid-cols-1 lg:grid-cols-3 gap-x-7 gap-y-4 shadow-none">
                  <div className="flex-grow flex gap-3">
                    <div className="flex items-center min-w-[110px] lg:min-w-min">
                      <label htmlFor="campaign" className="mr-1 lg:mr-2.5">
                        Campagne
                      </label>
                      <QuickHelp>Période du 1er juillet N au 30 juin N+1</QuickHelp>
                    </div>
                    <Select
                      inputId="campaign"
                      name="campaignId"
                      options={[{label: 'Toutes', value: 'all'}, ...collectDeliveriesStore.getCollectCampaigns]}
                    />
                  </div>
                  <div className="flex-grow flex gap-3">
                    <div className="flex items-center min-w-[110px] lg:min-w-min">
                      <label htmlFor="species" className="mr-1 lg:mr-2.5">
                        Culture
                      </label>
                      <QuickHelp>Choisissez parmi la liste pour afficher les résultats par culture</QuickHelp>
                    </div>
                    <Select
                      inputId="species"
                      name="speciesId"
                      options={[
                        {
                          label: 'Tous',
                          value: 'all',
                        },
                        ...collectDeliveriesStore.subFamilyAsOptions,
                      ]}
                      placeholder="Tous"
                    />
                  </div>
                  <div className="flex-grow flex gap-3">
                    <div className="flex items-center min-w-[110px] lg:min-w-min">
                      <label htmlFor="item" className="mr-1 lg:mr-2.5">
                        Variété
                      </label>
                      <QuickHelp>Choisissez parmi la liste pour afficher les résultats par variété</QuickHelp>
                    </div>
                    <Select
                      inputId="item"
                      name="itemId"
                      options={[{value: 'all', label: 'Tous'}, ...getFilteredItemOptions()]}
                    />
                  </div>
                </FiltersCard>
                <div className="DeliverySummary">
                  <div className="DeliverySummary-item">
                    <div className="DeliverySummary-label">Quantité livrée :</div>
                    <QuickHelp backgroundColor="bg-white">Quantité aux normes de marchandises livrées</QuickHelp>
                    <div className="DeliverySummary-value">
                      {getFormatedQuantity(collectDeliveriesStore.global?.totalQty)}
                    </div>
                  </div>

                  {values.speciesId !== 'all' && (
                    <>
                      <div className="DeliverySummary-item">
                        <div className="DeliverySummary-label">Reste à livrer :</div>
                        <QuickHelp backgroundColor="bg-white">Quantité contractualisée non livrée</QuickHelp>
                        <div
                          className={getDeliverySummaryValueClasses(
                            collectDeliveriesStore?.global?.totalRestToDelivery
                          )}
                        >
                          {getFormatedQuantity(collectDeliveriesStore?.global?.totalRestToDelivery)}
                        </div>
                      </div>
                      <div className="DeliverySummary-item">
                        <div className="DeliverySummary-label">Reste à vendre :</div>
                        <QuickHelp backgroundColor="bg-white">Quantité livrée non rattachée à un contrat</QuickHelp>
                        <div className={getDeliverySummaryValueClasses(collectDeliveriesStore?.global?.restToSell)}>
                          {getFormatedQuantity(collectDeliveriesStore?.global?.restToSell)}
                        </div>
                      </div>
                    </>
                  )}

                  <div className="DeliverySummary-break"></div>

                  <div className="DeliverySummary-item">
                    <div className="DeliverySummary-label">Reste à facturer :</div>
                    <QuickHelp backgroundColor="bg-white">
                      Quantité rattachée à un contrat mais pas encore facturée
                    </QuickHelp>
                    <div className={getDeliverySummaryValueClasses(collectDeliveriesStore?.global?.totalRestToInvoice)}>
                      {getFormatedQuantity(collectDeliveriesStore?.global?.totalRestToInvoice)}
                    </div>
                  </div>

                  <div className="DeliverySummary-item">
                    <div className="DeliverySummary-label">Quantité facturée :</div>
                    <div className={getDeliverySummaryValueClasses(collectDeliveriesStore?.global?.totalPurch)}>
                      {getFormatedQuantity(collectDeliveriesStore?.global?.totalPurch)}
                    </div>
                  </div>
                </div>
                <div className="mb-4">
                  <div className="flex justify-between gap-5">
                    <button className="flex items-center lg:hidden text-interactif" onClick={toggle} type="button">
                      <img src="/icons/filters.svg" alt="" className="me-1.5" />
                      Filtres
                    </button>
                    <Input
                      name="search"
                      placeholder="Rechercher"
                      type="text"
                      className={'h-10 hidden lg:block max-w-xl'}
                      iconAfter={<Search />}
                    />
                    <ExportButtons onPdfClick={onDownloadPdf} onCsvClick={onDownloadCsv} />
                  </div>
                  <Input
                    name="search"
                    placeholder="Rechercher"
                    type="text"
                    className="lg:hidden my-4"
                    iconAfter={<Search />}
                  />
                </div>
                <div className="flex justify-between gap-4">
                  <div className="hidden lg:flex gap-5 grow max-w-[1000px] ">
                    <Select
                      label="Type de contrat"
                      name="contractType"
                      multiple={true}
                      placeholder="Tous"
                      options={contractTypeOptions}
                      selectAllOption="Tous"
                    />
                  </div>
                  <TagsContainer>
                    {currentCampaignId === values.campaignId &&
                      tagsOptions.map((tag) => (
                        <Tag
                          key={tag.value}
                          label={tag.label}
                          onClick={() =>
                            setFieldValue('tagsOption', values.tagsOption === tag.value ? 'all' : tag.value)
                          }
                          active={values.tagsOption === tag.value}
                        />
                      ))}
                  </TagsContainer>
                </div>

                <OptionModal
                  active={active}
                  title="Filtres"
                  toggle={toggle}
                  actions={<Button label="Fermer" onClick={() => console.log('appliquer')} className="w-full" />}
                >
                  <h4 className="text-title-4 text-green-100 mb-3.5">Type de contrat :</h4>
                  <CheckBoxAllSelector
                    name="contractType"
                    options={contractTypeOptions}
                    label="Tous"
                    className={'mb-7'}
                  />
                  {contractTypeOptions.map(({value, label}: any, idx: React.Key | null | undefined) => (
                    <CheckBox key={idx} name="contractType" label={label} value={value} />
                  ))}
                </OptionModal>
              </Form>
            );
          }}
        />
      </div>

      {!collectDeliveriesStore.fetchCollectDeliveriesCampaignsState.isDone ? (
        <Loader />
      ) : (
        <div>
          <div className="flex flex-col gap-3.5 lg:gap-5">
            {Array.isArray(list) && list.length > 0 ? (
              list.map((c, idx) => <DeliveryRow key={idx} collectDelivery={c} />)
            ) : (
              <NoData message={getNoDataMessage(filters, currentCampaignId)} />
            )}
          </div>

          <Pagination
            activePage={pageNumber}
            totalItemsCount={collectDeliveriesStore.fetchCollectDeliveriesState.total ?? 0}
            onChange={onPageChange}
          />
        </div>
      )}
    </>
  );
});

const DeliveriesList: React.FC = observer(() => {
  const {campaigns: campaignsStore, collectDeliveries: collectDeliveriesStore} = useStore();

  const areCampaignsOptionsLoaded = ['done', 'error'].includes(collectDeliveriesStore.fetchCampaignsDeliveriesState.state);

  useEffect(() => {
    if (!areCampaignsOptionsLoaded) {
      collectDeliveriesStore.fetchCampaignsDeliveries();
    }
  }, []);

  const currentCampaignId = campaignsStore.currentCampaign?.id;

  if (isUndefined(currentCampaignId) || !areCampaignsOptionsLoaded) {
    return <Loader topMargin />;
  }

  return <DeliveriesListWrapper />;
});

export default DeliveriesList;
