import {flow} from 'mobx-state-tree';
import {IActivationStoreModel} from '@stores/activation/store';
import {IActivationFields} from '@components/Forms/ActivationForm';
import {createUser} from '@services/activation';

export default (self: IActivationStoreModel) => {
  return {
    activate: flow(function* activate(data: IActivationFields) {
      const serviceState = self.activationState;
      serviceState.pending();
      try {
        const response: any = yield createUser(data);

        if (response.errors) {
          self.hasBeenCreated = false;
          serviceState.fail({sendToast: false, message: JSON.stringify(response.errors)});
        } else {
          self.hasBeenCreated = true;
          serviceState.done();
        }
        return response;
      } catch (e: any) {
        self.hasBeenCreated = false;
        if (Object.prototype.hasOwnProperty.call(e, 'errors')) {
          serviceState.fail({sendToast: false, message: JSON.stringify(e.errors)});
        }
      }
    }),
  };
};
