import {fetchFds} from '@services/lexagri';
import {defaultErrorToast} from '@utils/toast';
import React from 'react';
import './styles.scss';
import NoData from '@components/NoData/NoData';
import {getNoDataMessage} from '@utils/tables';
import {toast} from 'react-toastify';
import {Card} from '@components/UI/Card/Card';
import {CardRow} from '@components/UI/Card/CardRow';
import {CardSeparator} from '@components/UI/Card/CardSeparator';
import {CardTitle} from '@components/UI/Card/CardTitle';

interface IFDSRow {
  fds: any;
}
const FdsRow: React.FC<IFDSRow> = ({fds}) => {
  const getFdsDocument = async (fdsNum: string) => {
    const downloadToaster = toast.info('Téléchargement en cours', {
      autoClose: false,
      hideProgressBar: true,
    });
    const response = await fetchFds(fdsNum);
    if (response.url) {
      toast.dismiss(downloadToaster);
      toast.update(downloadToaster, {
        render: 'Téléchargement réussi',
        type: 'success',
        autoClose: 1000,
      });
      window.open(response.url, '_blank');
    } else {
      defaultErrorToast(
        'Une erreur est survenue lors du téléchargement du document, veuillez réessayer ultérieurement ou contacter un administrateur'
      );
    }
  };

  return (
    <Card>
      <CardRow>
        <div className="CardDocument flex flex-col md:flex-row">
          <div className="md:w-1/3">
            <CardTitle
              title={fds.description}
              subTitle={fds.item.manufacturerCode}
              onDownload={() => getFdsDocument(fds.item.lexagriCode)}
            />
          </div>
          <CardSeparator className="md:hidden" />
          <div className="Card-info flex flex-col md:w-1/3 md:items-center">
            <span className="Card-label mb-1">Délais de fin de distribution</span>
            <span className="Card-value flex w-1/2 md:justify-center">
              {fds.item.dateOfLastDistribution === '1753-01-01T00:00:00.000Z'
                ? '-'
                : new Date(fds.item.dateOfLastDistribution).toLocaleDateString('fr-FR')}
            </span>
          </div>
          <div className="Card-info flex flex-col md:w-1/3 md:items-center">
            <span className="Card-label mb-1">Délais de fin d'utilisation</span>
            <span className="Card-value flex w-1/2 md:justify-center">
              {fds.item.limitUseDate === '1753-01-01T00:00:00.000Z'
                ? '-'
                : new Date(fds.item.limitUseDate).toLocaleDateString('fr-FR')}
            </span>
          </div>
        </div>
      </CardRow>
    </Card>
  );
};
export default FdsRow;
