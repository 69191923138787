import {IApproOrdersStoreModel} from './store';
import {flow} from 'mobx-state-tree';
import {fetchApproOrders, IApproOrdersFilter} from '@services/navagri';
import {ConcurrentHelper} from '@utils/ConcurrentHelper';
import {getPaginationParams} from '@utils/tables';

export default (self: IApproOrdersStoreModel) => {
  return {
    fetchOrdersGlobalFiltersOptions: flow(function* () {
      const serviceState = self.fetchOrdersGlobalFiltersOptionsState;
      serviceState.pending();
      try {
        const {isLastAction} = ConcurrentHelper.setAction('fetchOrdersGlobalFiltersOptions');
        const {campaigns} = yield fetchApproOrders({
          $limit: 0,
          $skip: 0,
          groupBy: 'order',
        });
        if (!isLastAction()) {
          return;
        }
        self.campaigns = campaigns;
        serviceState.done();
      } catch (error) {
        serviceState.fail({error});
      }
    }),
    fetchOrders: flow(function* (page: number, filters?: IApproOrdersFilter) {
      const serviceState = self.fetchOrdersState;
      serviceState.pending();
      try {
        const {isLastAction} = ConcurrentHelper.setAction('fetchOrders');
        const {data, campaigns, facets, total, limit} = yield fetchApproOrders({
          ...getPaginationParams(page),
          ...filters,
        });
        if (!isLastAction()) {
          return;
        }
        self.list = data;
        self.campaigns = campaigns;
        self.facets = facets;
        serviceState.setPagination(total, limit);
        serviceState.done();
      } catch (error) {
        serviceState.fail({error});
      }
    }),
  };
};
