import fetch from '@utils/fetch';
import IFeathersDefaultFilters from 'src/constants/feathers.defaultFilters';

const endPoint = `${process.env.NAV_URL}`;
//const endPoint = `http://localhost:3033`

const endPoints = {
  contracts: `${endPoint}/contracts`,
  collectInvoices: `${endPoint}/collect-invoices`,
  download: `${endPoint}/download`,
  campaigns: `${endPoint}/campaigns`,
  species: `${endPoint}/species`,
  customerAccounts: `${endPoint}/customer-accounts`,
  supplierAccounts: `${endPoint}/supplier-accounts`,
  orders: `${endPoint}/cmd-lines`,
  bills: `${endPoint}/bill-lines`,
  soldOrders: `${endPoint}/sold-orders`,
  salesShipmentLines: `${endPoint}/sales-shipment-lines`,
  fetchFDSItems: `${endPoint}/service-fds`,
  varietySpecies: `${endPoint}/varietySpecies`,
  getPdf: `${endPoint}/get-pdf`,
  items: `${endPoint}/items`,
  collectLines: `${endPoint}/collect-lines`,
  collectDeliveries: `${endPoint}/collect-deliveries`,
  odv: `${endPoint}/odv`,
  approOrders: `${endPoint}/appro-orders`,
  approContracts: `${endPoint}/appro-contracts`,
  approDeliveries: `${endPoint}/appro-deliveries`,
  approInvoices: `${endPoint}/appro-invoices`,
};

export interface IContractFilters extends IFeathersDefaultFilters {
  contractType?: string[];
  campaignId?: string;
  speciesId?: string;
  itemId?: string;
  canceled?: 0 | 1;
  status?: string[]; // 'en cours' | 'solde' | 'non solde'
  tagsOption?: string;
  search?: string;
}

export interface IOrdersFilters extends IFeathersDefaultFilters {
  documentType?: number;
  campaignId?: string;
  groupBy?: string;
  description?: string;
}

export interface IBillsFilter extends IFeathersDefaultFilters {
  groupBy?: string;
  campaignId?: string;
}

export interface IApproOrdersFilter {
  groupBy?: string;
  campaignId?: string;
  tagsOption?: string;
  search?: string;
  itemFamilyCode?: string[];
  status?: string[];
}

export interface IApproContractsFilter {
  groupBy?: string;
  campaignId?: string;
  search?: string;
  itemFamilyCode?: string[];
  status?: string[];
}

export interface IApproDeliveriesFilter {
  groupBy?: string;
  campaignId?: string;
  tagsOption?: string;
  search?: string;
  itemFamilyCode?: string[];
  status?: string[];
}

export interface IApproInvoicesFilter {
  groupBy?: string;
  campaignId?: string;
  search?: string;
  familyCode?: string[];
  status?: string[];
}

export interface ISoldOrdersFilter extends IFeathersDefaultFilters {
  groupBy?: string;
  campaignId?: string;
}

export interface IDeliveriesFilter extends IFeathersDefaultFilters {
  groupBy?: string;
  campaignId?: string;
  withFds?: boolean;
  speciesId?: string;
  itemId?: string;
  receiptedDate?: any;
  status?: object;
}

export interface IAdivalorFilters extends IFeathersDefaultFilters {
  date?: string;
  locations?: string;
  types?: string;
}

export interface IFDSFilter extends IFeathersDefaultFilters {
  campaignId?: string;
  withFds?: boolean;
}

export const fetchContracts = (filters: IContractFilters) => fetch(endPoints.contracts, 'get', filters);

export const fetchOrders = (filters: IOrdersFilters) => fetch(endPoints.orders, 'get', filters);

export const fetchItems = (filters: any) => fetch(endPoints.items, 'get', filters);

export const fetchBills = (filters: IBillsFilter) => fetch(endPoints.bills, 'get', filters);

export const fetchApproDeliveries = (filters: IApproDeliveriesFilter & IFeathersDefaultFilters) =>
  fetch(endPoints.approDeliveries, 'get', filters);

export const fetchApproOrders = (filters: IApproOrdersFilter & IFeathersDefaultFilters) =>
  fetch(endPoints.approOrders, 'get', filters);

export const fetchApproInvoices = (filters: IApproInvoicesFilter & IFeathersDefaultFilters) =>
  fetch(endPoints.approInvoices, 'get', filters);

export const fetchApproContracts = (filters: IApproContractsFilter & IFeathersDefaultFilters) =>
  fetch(endPoints.approContracts, 'get', filters);

export const fetchSoldOrders = (filters: ISoldOrdersFilter) => fetch(endPoints.soldOrders, 'get', filters);

export const fetchSalesShipmentLines = (filters: IDeliveriesFilter) =>
  fetch(endPoints.salesShipmentLines, 'get', filters);

export const fetchFDSItems = (filters: IFDSFilter) => fetch(endPoints.fetchFDSItems, 'get', filters);

export const getPdfDownloadLink = (id: string, type: string) => `${endPoints.download}/${type}?num=${id}`;

export interface ICollectFilters extends IFeathersDefaultFilters {
  campaignId?: string;
  invoiced?: number;
  receiptedDate?: {$gte?: string; $lte?: string};
}

export interface ICollectInvoicesFilters extends IFeathersDefaultFilters {
  campaignId?: string;
  subFamily?: string;
  specie?: string;
  search?: string;
  contractTypes?: string[];
  tagsOption?: string;
}

export const fetchCollectInvoices = (filters: ICollectInvoicesFilters) =>
  fetch(endPoints.collectInvoices, 'get', filters);

export interface ICollectDeliveriesFilters extends IFeathersDefaultFilters {
  campaignId?: string;
  speciesId?: string;
  itemId?: string;
  search?: string;
  contractType?: string;
  tagsOption?: string[];
}

export const fetchCollectDeliveries = (filters: ICollectDeliveriesFilters) =>
  fetch(endPoints.collectDeliveries, 'get', filters);

export const fetchCampaigns = () => fetch(endPoints.campaigns, 'get');

// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface ISpeciesFilters extends IFeathersDefaultFilters {}

export const fetchSpecies = (filters?: ISpeciesFilters) => fetch(endPoints.species, 'get', filters);

export const fetchVarietySpecies = (filters?: ISpeciesFilters) => fetch(endPoints.varietySpecies, 'get', filters);

export const getPdf = (data: any) => fetch(`${endPoints.getPdf}`, 'post', data);

export interface IAccountingItem {
  id: number;
  postingDate: string;
  type: number;
  num: string;
  echeance: string;
  linked: {
    postingDate: Date | null;
    num: string | null;
    type: number | null;
    echeance: Date | null;
    letter: string | null;
    initialAmount: number | null;
  };
  letter: string;
  costumerId: string;
}

export interface IAccountingFilters extends IFeathersDefaultFilters {
  num?: {
    $like?: string;
  };
  postingDate?: {
    $lte?: string;
    $gte?: string;
  };
  like?: string;
  limit?: number;
  skip?: number;
  type?: string;
  search?: string;
  tagsOption?: string;
  sortedDocument?: {
    name: string;
    desc: boolean | null;
  };
}

export const fetchCustomerAccounts = (filters: IAccountingFilters): Promise<{data: IAccountingItem[]}> => {
  return fetch(endPoints.customerAccounts, 'get', filters);
};

export const fetchSupplierAccounts = (filters: IAccountingFilters): Promise<{data: IAccountingItem[]}> => {
  return fetch(endPoints.supplierAccounts, 'get', filters);
};

export interface OdvDataPeriod {
  period: string;
  cotation: number | null;
}

export interface OdvDataOtherPeriod {
  campaignId: number;
  periods: [];
}

export interface OdvData {
  lastUpdate: string;
  campaignId: string;
  contactNum: string;
  cotation: number | null;
  maxQty: number;
  month: string;
  otherPeriods: OdvDataOtherPeriod[];
  specy: {
    id: string;
    name: string;
    year: string;
  };
}

export const fetchOdv = (specyId: string, campaignId: number, month: number): Promise<OdvData> =>
  fetch(`${endPoints.odv}?specyId=${specyId}&campaignId=${campaignId}&month=${month}&year=${campaignId}`, 'get');

interface IOdvPayload {
  id: string;
  contactId: string;
  specyId: string;
  month: number;
  year: string;
  qty: number;
  unitPrice: number;
  cgv: boolean;
  campaignId: string;
}

export const postOdvData = (payload: IOdvPayload) => fetch(`${endPoints.odv}`, 'POST', payload);
