import {ICollectInvoicesStoreModel} from '@stores/collect-invoices/store';
import {renameSpecie} from '@utils/species/species';

export default (self: ICollectInvoicesStoreModel) => {
  return {
    get getCampaignOptions() {
      return self.globalFacets?.availableCampaigns.toJSON().toSorted((a, b) => a.label.localeCompare(b.label)) ?? [];
    },
    get getSubFamilyOptions() {
      return (
        self.globalFacets?.availableSubFamilies
          .toJSON()
          .map((facet) => ({
            label: renameSpecie(facet.label) as string,
            value: facet.value,
          }))
          .toSorted((a, b) => a.label.localeCompare(b.label)) ?? []
      );
    },
    get getSpecieOptions() {
      return (
        self.globalFacets?.availableSpecies
          .toJSON()
          .map((facet) => ({
            label: renameSpecie(facet.label) as string,
            subFamilyValue: facet.subFamilyValue,
            value: facet.value,
          }))
          .toSorted((a, b) => a.label.localeCompare(b.label)) ?? []
      );
    },
    get getContractTypesOptions() {
      return self.facets?.availableContractTypes.toJSON().toSorted((a, b) => a.label.localeCompare(b.label)) ?? [];
    },
  };
};
