import React, {useEffect, useState} from 'react';
import './styles.scss';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faSortUp} from '@fortawesome/free-solid-svg-icons/faSortUp';
import {faSortDown} from '@fortawesome/free-solid-svg-icons/faSortDown';
import {faSort} from '@fortawesome/free-solid-svg-icons/faSort';
import {faPaperclip} from '@fortawesome/free-solid-svg-icons/faPaperclip';
import {getCreditRender, getDebitRender, getRestToPaid} from '@components/Accounting/AccountingView/columns';
import {formatDate} from '@utils/string';
import {formatData, getType} from '@components/Accounting/AccountingView';
import {customersDocuments, suppliersDocuments} from '@constants/documentsDownload.options';
import useDownloadPdf from '@hooks/useDownloadPdf';
import DueDateIndicator from '@components/UI/DueDateIndicator/DueDateIndicator';
import {useStore} from '@stores/index';
import EnvironmentService from '@utils/environmentService';
import {cn} from "@utils/class-names";

interface ILinkedData {
  postingDate: string | null;
  num: string | null;
  type: number | null;
  echeance: string | null;
  letter: string | null;
  initialAmount: number | null;
}

export interface IFormattedData {
  RowNum: string;
  postingDate: string;
  num: string;
  type: number;
  echeance: string;
  letter: string;
  account: string;
  restToPaid: number;
  initialAmount: number;
  linked: ILinkedData[];
}

interface AccountingTableDesktopProps {
  data: IFormattedData[];
  setFieldValue: any;
  typeLabel: string;
}

const AccountingTableDesktop: React.FC<AccountingTableDesktopProps> = ({data, setFieldValue, typeLabel}) => {
  const [collapsedRows, setCollapsedRows] = useState<Record<string, boolean>>({});
  const [formattedData, setFormattedData] = useState<IFormattedData[]>([]);
  const {accounting: accountingStore} = useStore();

  const {download: download} = useDownloadPdf();

  const downloadPdfLoader = (value: string, type: number, name: string) => {
    download(value, type, name);
  };

  // Handling collapsible row
  const toggleRow = (id: number) => {
    setCollapsedRows({
      ...collapsedRows,
      [id]: !collapsedRows[id],
    });
  };

  useEffect(() => {
    setFormattedData(formatData(data));
  }, []);

  const handleChange = (name: string) => {
    let nextValue: boolean | null;

    if (accountingStore.sortedColumn === name) {
      if (name === 'letter') {
        if (accountingStore.isDescending === false) {
          nextValue = true;
        } else {
          nextValue = null;
          name = '';
        }
      } else {
        if (accountingStore.isDescending === true) {
          nextValue = false;
        } else {
          nextValue = null;
          name = '';
        }
      }
    } else {
      nextValue = name !== 'letter';
    }

    accountingStore.setIsDescending(nextValue, name);
    setFieldValue('sortedDocument', name);
  };

  return (
    <table className="AccountingTableDesktop">
      <thead>
        <tr>
          <th
            onClick={() => {
              handleChange('postingDate');
            }}
            className="AccountingTableDesktop-sortedColumn"
          >
            Date
            <FontAwesomeIcon
              icon={
                accountingStore.sortedColumn === 'postingDate'
                  ? accountingStore.isDescending === null
                    ? faSort
                    : !accountingStore.isDescending
                    ? faSortUp
                    : faSortDown
                  : faSort
              }
            />
          </th>
          <th>Type</th>
          <th>Document</th>
          <th
            onClick={() => {
              handleChange('letter');
            }}
            className="AccountingTableDesktop-sortedColumn"
          >
            Lettrage
            <FontAwesomeIcon
              icon={
                accountingStore.sortedColumn === 'letter'
                  ? accountingStore.isDescending === null
                    ? faSort
                    : !accountingStore.isDescending
                    ? faSortUp
                    : faSortDown
                  : faSort
              }
            />
          </th>
          <th
            onClick={() => {
              handleChange('echeance');
            }}
            className="AccountingTableDesktop-sortedColumn"
          >
            Échéance
            <FontAwesomeIcon
              icon={
                accountingStore.sortedColumn === 'echeance'
                  ? accountingStore.isDescending === null
                    ? faSort
                    : !accountingStore.isDescending
                    ? faSortUp
                    : faSortDown
                  : faSort
              }
            />
          </th>
          <th>Débit (TTC)</th>
          <th>Crédit (TTC)</th>
          <th
            onClick={() => {
              handleChange('restToPaid');
            }}
            className="AccountingTableDesktop-sortedColumn text-right"
          >
            Montant restant dû (TTC)
            <FontAwesomeIcon
              icon={
                accountingStore.sortedColumn === 'restToPaid'
                  ? accountingStore.isDescending === null
                    ? faSort
                    : !accountingStore.isDescending
                    ? faSortUp
                    : faSortDown
                  : faSort
              }
            />
          </th>
          <th></th>
        </tr>
      </thead>
      <tbody className="AccountingTableDesktop-body">
        {Array.isArray(formattedData) &&
          formattedData.length > 0 &&
          formattedData.map((document, id) => {
            let navDoctype: any = null;
            if (typeLabel == 'supplier') {
              navDoctype = suppliersDocuments.find((f) => f.type === document.type);
            }
            if (typeLabel == 'customer') {
              navDoctype = customersDocuments.find((f) => f.type === document.type);
            }

            return (
              <React.Fragment key={id}>
                <tr className={`AccountingTableDesktop-body-row ${collapsedRows[id] ? 'collapsed' : ''}`} key={id}>
                  <td className="AccountingTableDesktop-body-row-date">{formatDate(document.postingDate, false)}</td>
                  <td
                    className={cn('AccountingTableDesktop-body-row-type', {
                      blackAndWhiteIcon: EnvironmentService.instanceConfig.global.downloadIconInBlackAndWhite,
                    })}
                  >
                    {navDoctype ? (
                      <span onClick={() => downloadPdfLoader(document.num, navDoctype.navType, document.num)}>
                        <img src="/icons/file.svg" alt="" /> {getType(document.type)}
                      </span>
                    ) : (
                      <> {getType(document.type)}</>
                    )}
                  </td>
                  <td>{document.num}</td>
                  <td className="AccountingTableDesktop-body-row-date">
                    {document.letter.length > 0 ? document.letter : ''}
                  </td>
                  <td className="AccountingTableDesktop-body-row-date">
                    {document.restToPaid > 0 ? (
                      <DueDateIndicator dueDate={document.echeance} total={document.restToPaid} />
                    ) : (
                      formatDate(document.echeance, false)
                    )}
                  </td>
                  <td>{getDebitRender('customer', document.initialAmount, true)}</td>
                  <td>{getCreditRender('customer', document.initialAmount, true)}</td>
                  <td className="AccountingTableDesktop-body-row-restToPaid">
                    {getRestToPaid(document.type, document.restToPaid)}
                  </td>
                  {document.linked.length > 0 ? (
                    <td onClick={() => toggleRow(id)} className="AccountingTableDesktop-body-row-linkedInvoices">
                      <div className="AccountingTableDesktop-body-row-linkedInvoices-paperClip">
                        <FontAwesomeIcon icon={faPaperclip} />
                        <div className="AccountingTableDesktop-body-row-linkedInvoices-paperClip-number">
                          {document.linked.length}
                        </div>
                      </div>
                      {collapsedRows[id] ? <FontAwesomeIcon icon={faSortUp} /> : <FontAwesomeIcon icon={faSortDown} />}
                    </td>
                  ) : (
                    <td className="AccountingTableDesktop-body-row-linkedInvoices" />
                  )}
                </tr>
                {collapsedRows[id] && document.linked
                  ? document.linked.map((linkedItem: ILinkedData, index: number) => {
                      let navDoctype: any = null;
                      if (typeLabel == 'supplier') {
                        navDoctype = suppliersDocuments.find((f) => f.type === linkedItem.type);
                      }
                      if (typeLabel == 'customer') {
                        navDoctype = customersDocuments.find((f) => f.type === linkedItem.type);
                      }

                      return (
                        <tr key={index} className={'AccountingTableDesktop-body-collapsibleRow'}>
                          <td>{formatDate(linkedItem.postingDate, false)}</td>
                          <td className={'AccountingTableDesktop-body-collapsibleRow-type'}>
                            {navDoctype ? (
                              <span
                                onClick={() => downloadPdfLoader(linkedItem.num, navDoctype.navType, linkedItem.num)}
                              >
                                <img src="/icons/file.svg" alt="" /> {getType(linkedItem.type)}
                              </span>
                            ) : (
                              <> {getType(linkedItem.type)}</>
                            )}
                          </td>
                          <td>{linkedItem.num}</td>
                          <td>{linkedItem.letter}</td>
                          <td>{formatDate(linkedItem.echeance, false)}</td>
                          <td>{getDebitRender('customer', linkedItem.initialAmount, true)}</td>
                          <td>{getCreditRender('customer', linkedItem.initialAmount, true)}</td>
                          <td></td>
                          <td></td>
                        </tr>
                      );
                    })
                  : null}
              </React.Fragment>
            );
          })}
      </tbody>
    </table>
  );
};

export default AccountingTableDesktop;
