import React from 'react';
import {observer} from 'mobx-react-lite';
import {useStore} from '@stores/index';
import ContactCard from '@components/Card/contactCard';
import './style.scss';
import EnvironmentService from '@utils/environmentService';

const instanceConfig = EnvironmentService.getInstanceConfig();

const RepresentativeList: React.FC = observer(() => {
  const {sso: SSOStore} = useStore();
  const {selectedExtranetUser: currentAccount, user: currentUser, salesPeople: salesPeople} = SSOStore;
  const customerLocation = currentAccount?.contact?.customer?.location;

  salesPeople.sort((a, b) => {
    return b.prodActivityCode!.localeCompare(a.prodActivityCode!);
  });

  return (
    <div className="RepresentativeList Card relative">
      <div className="Card-row">
        {salesPeople.length > 0 && (
          <>
            <div className="RepresentativeList-interlocuteurSubTitle uppercase">Mes responsables commerciaux</div>

            <div className="ContactCard-container mb-4 md:mb-7">
              {salesPeople
                .filter(
                  (people) => !(instanceConfig.profil.hidePA && people.prodActivityCode === 'PA')
                )
                .map((people) => (
                  <ContactCard
                    key={people.prodActivityCode}
                    representative={people.salesPersonPurchaser!}
                    description={
                      people.prodActivityCode === 'PV'
                        ? 'Votre Responsable Commercial Productions Végétales'
                        : 'Votre Responsable Commercial Productions Animales'
                    }
                  />
                ))}
            </div>
          </>
        )}

        {customerLocation && (
          <>
            <div className="RepresentativeList-interlocuteurSubTitle uppercase">Mon dépôt</div>
            <div className="ContactCard-container">
              <div>
                <ContactCard description="VOTRE INTERLOCUTEUR PRIVILEGIE" representative={customerLocation} />
              </div>
            </div>
          </>
        )}
      </div>
    </div>
  );
});
export default RepresentativeList;
