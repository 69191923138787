import React, {useMemo} from 'react';
import {CardInfo, CardInfoFlag} from '@components/UI/Card/CardInfo';
import {CardRow, CardRowType} from '@components/UI/Card/CardRow';
import {CardSubTitle} from '@components/UI/Card/CardSubTitle';
import {CardInfosWrapper} from '@components/UI/Card/CardInfosWrapper';
import {Card} from '@components/UI/Card/Card';
import {CardTitleContainer} from '@components/UI/Card/CardTitleContainer';
import {CardTitle} from '@components/UI/Card/CardTitle';
import {formatDate} from '@utils/string';
import {CardRowCountWrapper, CardRowCountWrapperItem} from '@components/UI/Card/CardRowCountWrapper';
import {isDef} from '@lepicard/utils';
import {formatNumber} from '@utils/number';
import {IApproInvoicesRow} from '@components/ApproInvoices/ApproInvoicesRows/ApproInvoicesRows';
import {
  ApproInvoicesStatusEnum,
  ApproInvoicesStatusTag,
} from '@components/ApproInvoices/ApproInvoicesStatusTag/ApproInvoicesStatusTag';
import {CardSeparator} from '@components/UI/Card/CardSeparator';
import {getTransformedDisplayedLines} from '@components/Orders/OrdersRow/OrdersRowShared';
import {CardInfoGroup} from '@components/UI/Card/CardInfoGroup';
import {getRemainingDays, getRemainingDaysLabel} from '@utils/date';
import {AlertNotification, AlertTheme} from '@components/UI/AlertNotification/AlertNotification';
import useDownloadPdf from '@hooks/useDownloadPdf';

interface InvoicesGeneralSubRow {
  invoice: any;
  line: any;
  attachedLines: any[];
}

const SubRow: React.FC<InvoicesGeneralSubRow> = ({line: product, attachedLines}) => {
  const quantityLabel = formatNumber(product.quantity as number)
    .round()
    .spaces()
    .renderWithUnit(product.unitOfMeasureCode);

  const netUnitPriceLabel = formatNumber(product.netUnitPrice as number)
    .fixed()
    .spaces()
    .renderWithUnit('€');

  const amountHtLabel = formatNumber(product.amount as number)
    .fixed()
    .spaces()
    .renderWithUnit('€');

  return (
    <CardRow type={CardRowType.SUB}>
      <CardTitleContainer>
        <CardSubTitle title={product.description} subTitle={formatDate(product.shipmentDate)} />
      </CardTitleContainer>
      <CardSeparator />
      <CardInfosWrapper>
        <CardInfo label="Commande liée" value={product.orderNo} />
        {isDef(product.contractNo) && <CardInfo label="Contrat lié" value={product.contractNo} />}
      </CardInfosWrapper>
      <CardSeparator />
      <CardInfosWrapper>
        <CardInfoGroup>
          <CardInfo label="Quantité" value={quantityLabel} flags={[CardInfoFlag.QUANTITY]} />
          <CardInfo label="Prix unitaire HT" value={netUnitPriceLabel} flags={[CardInfoFlag.QUANTITY]} />
        </CardInfoGroup>
        <CardSeparator className="lg:hidden" />
        <CardInfoGroup>
          <CardInfo label="Montant HT" value={amountHtLabel} flags={[CardInfoFlag.QUANTITY]} />
          {attachedLines.map((line, key) => (
            <CardInfo
              key={key}
              label={line.description}
              value={formatNumber(line.amountIncludingVAT as number)
                .round()
                .fixed()
                .renderWithUnit('€')}
              flags={[CardInfoFlag.QUANTITY]}
            />
          ))}
        </CardInfoGroup>
      </CardInfosWrapper>
    </CardRow>
  );
};

const SubRowSimple: React.FC<InvoicesGeneralSubRow> = ({line}) => {
  const totalPriceLabel = formatNumber(line.amountIncludingVAT as number)
    .fixed()
    .spaces()
    .renderWithUnit('€');

  return (
    <CardRow type={CardRowType.SUB}>
      <CardSubTitle title={line.description} />
      <CardSeparator />
      <CardInfo label="Montant TTC" value={totalPriceLabel} flags={[CardInfoFlag.IMPORTANT, CardInfoFlag.QUANTITY]} />
    </CardRow>
  );
};

export const ApproInvoicesRowByDelivery: React.FC<IApproInvoicesRow> = ({data}) => {
  const {displayedLines, attachedLinesByProductLine} = useMemo(() => {
    return getTransformedDisplayedLines(data.lines);
  }, [data.lines]);

  const subRows = useMemo<CardRowCountWrapperItem<InvoicesGeneralSubRow>[]>(() => {
    return displayedLines.map((l: any) => {
      const isProduct = l.type === 2;
      const attachedLines = attachedLinesByProductLine.get(l.lineNo) ?? [];

      return isProduct
        ? {
            params: {invoice: data, line: l, attachedLines: attachedLines},
            key: l.uniqueId,
            render: SubRow,
          }
        : {
            params: {invoice: data, line: l, attachedLines: []},
            key: l.uniqueId,
            render: SubRowSimple,
          };
    });
  }, [displayedLines]);

  const totalLedgerAmountLabel = formatNumber(data.totalLedgerAmount as number)
    .fixed()
    .spaces()
    .renderWithUnit('€');

  const totalAmountIncludingVATLabel = formatNumber(data.totalAmountIncludingVAT as number)
    .fixed()
    .spaces()
    .renderWithUnit('€');

  const warningRemainingDays = getRemainingDays(data.dueDate);
  const showWarningRemainingDays = !isDef(data.letter) && warningRemainingDays <= 31 && warningRemainingDays >= 0;

  const {download: downloadPdf} = useDownloadPdf();

  return (
    <Card>
      <CardRow>
        <CardTitleContainer>
          <CardTitle
            title={`FACTURE ${data.no}`}
            subTitle={formatDate(data.postingDate)}
            onDownload={() => downloadPdf(data.no, 7, data.no)}
          />
          {isDef(data.letter) && <ApproInvoicesStatusTag status={ApproInvoicesStatusEnum.SOLD} />}
        </CardTitleContainer>
      </CardRow>
      <CardRow>
        <CardInfosWrapper>
          <CardInfoGroup>
            <CardInfo label="Échéance" value={formatDate(data.dueDate)} />
            {showWarningRemainingDays && (
              <AlertNotification
                theme={warningRemainingDays <= 15 ? AlertTheme.RED : AlertTheme.YELLOW}
                label={`${getRemainingDaysLabel(data.dueDate)} restant${warningRemainingDays > 1 ? 's' : ''}`}
              />
            )}
          </CardInfoGroup>
          <CardInfo label="Lettrage" value={data.letter} />
          <CardSeparator className="lg:hidden" />
          <CardInfo label="Montant TTC" value={totalAmountIncludingVATLabel} flags={[CardInfoFlag.IMPORTANT]} />
          <CardInfo label="Montant restant dû" value={totalLedgerAmountLabel} flags={[CardInfoFlag.IMPORTANT]} />
        </CardInfosWrapper>
      </CardRow>
      <CardRowCountWrapper items={subRows} numberOfItemsInPreview={0} />
    </Card>
  );
};
