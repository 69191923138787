import React, {useState, PropsWithChildren, useContext, useEffect} from 'react';
import NavBar from '../NavBar';
import Sidebar from '../Sidebar';
import {matchPath, useNavigate, useLocation} from 'react-router-dom';
import './style.scss';
import {servicesRoutes} from '@routes';
import {cn} from '@utils/class-names';
import {Outlet} from 'react-router-dom';

type NavContainer = PropsWithChildren<{
  searchBarComponent?: React.ReactNode;
}>;

const NavContainer: React.FC<NavContainer> = ({children, searchBarComponent}) => {
  const [isSidebarOpened, setIsSidebarOpened] = useState(false);

  const toggleSideBar = () => setIsSidebarOpened(!isSidebarOpened);

  const navigate = useNavigate();

  const location = useLocation();
  const pathname = location.pathname;
  const noPaddingRoutes = [servicesRoutes.BSV];
  const contentWithPadding = !noPaddingRoutes.some((path) => matchPath(pathname, path));

  return (
    <>
      <div className={`mainContainer ${isSidebarOpened ? 'isSidebarOpened' : ''}`}>
        <NavBar
          toggleSideBar={toggleSideBar}
          isSidebarOpened={isSidebarOpened}
          searchBarComponent={searchBarComponent}
        />
        <div className="viewContainer">
          <Sidebar isSidebarOpened={isSidebarOpened} toggleSideBar={toggleSideBar} />
          <div className={cn('content w-full h-full overflow-y-hidden', {'px-4 lg:px-7 py-7': contentWithPadding})}>
            <Outlet />
          </div>
        </div>
        <div className={'footer'}>
          <ul>
            <li>
              <a onClick={() => navigate('/cgv')}>CGV</a>
            </li>
            <li>
              <span className={'footer-separator'}>·</span>
            </li>
            <li>
              <a onClick={() => navigate('/legal-notices')}>Mentions légales</a>
            </li>
            <li>
              <span className={'footer-separator'}>·</span>
            </li>
            <li className="footer-version">v. {process.env.APP_RELEASE}</li>
          </ul>
        </div>
      </div>
    </>
  );
};

export default NavContainer;
