import React from 'react';
import {CardInfo, CardInfoFlag} from '@components/UI/Card/CardInfo';
import {CardRow, CardRowType} from '@components/UI/Card/CardRow';
import {CardSubTitle} from '@components/UI/Card/CardSubTitle';
import {CardInfosWrapper} from '@components/UI/Card/CardInfosWrapper';
import {Card} from '@components/UI/Card/Card';
import {CardTitleContainer} from '@components/UI/Card/CardTitleContainer';
import {CardTitle} from '@components/UI/Card/CardTitle';
import {formatNumber} from '@utils/number';
import {isDef} from '@lepicard/utils';
import {CardSeparator} from '@components/UI/Card/CardSeparator';
import {Accordion} from '@components/UI/Accordion/Accordion';
import {IApproInvoicesRow} from '@components/ApproInvoices/ApproInvoicesRows/ApproInvoicesRows';
import {
  ApproInvoicesStatusEnum,
  ApproInvoicesStatusTag,
} from '@components/ApproInvoices/ApproInvoicesStatusTag/ApproInvoicesStatusTag';
import {CardInfoGroup} from '@components/UI/Card/CardInfoGroup';

interface IInvoiceSubRow {
  product: any;
  onClick: () => void;
  toggleIcon: React.ReactNode;
}

const SubRow: React.FC<IInvoiceSubRow> = ({product, onClick, toggleIcon}) => {
  const totalItems = product.invoices.length;
  const showMoreLabel = `${totalItems} ${totalItems > 1 ? 'factures' : 'facture'}`;
  return (
    <CardRow type={CardRowType.SUB_PREVIEW} onClick={onClick}>
      <div className="flex justify-between gap-1">
        <CardSubTitle title={product.description} />
        <div className="flex gap-2 items-center shrink-0">
          <p>{showMoreLabel}</p>
          {toggleIcon}
        </div>
      </div>
    </CardRow>
  );
};

interface IInvoiceSubSubRow {
  product: any;
  invoice: any;
}

const SubSubRow: React.FC<IInvoiceSubSubRow> = ({product, invoice}) => {
  const quantityLabel = formatNumber(invoice.quantity as number)
    .round()
    .spaces()
    .renderWithUnit(invoice.unitOfMeasureCode);

  const netUnitPriceLabel = formatNumber(invoice.netUnitPrice as number)
    .fixed()
    .spaces()
    .renderWithUnit('€');

  const amountHtLabel = formatNumber(invoice.amount as number)
    .fixed()
    .spaces()
    .renderWithUnit('€');

  return (
    <CardRow type={CardRowType.SUB}>
      <CardTitleContainer>
        <CardSubTitle title={`FACTURE ${invoice.documentNo}`} />
        {isDef(invoice.letter) && <ApproInvoicesStatusTag status={ApproInvoicesStatusEnum.SOLD} />}
      </CardTitleContainer>
      <CardSeparator />
      <CardInfosWrapper>
        <CardInfo label="Commande lié" value={invoice.orderNo} />
        {isDef(invoice.contractNo) && <CardInfo label="Contrat lié" value={invoice.contractNo} />}
      </CardInfosWrapper>
      <CardSeparator />
      <CardInfosWrapper>
        <CardInfoGroup>
          <CardInfo label="Quantité" value={quantityLabel} flags={[CardInfoFlag.QUANTITY]} />
          <CardInfo label="Prix unitaire HT" value={netUnitPriceLabel} flags={[CardInfoFlag.QUANTITY]} />
        </CardInfoGroup>
        <CardSeparator className="lg:hidden" />
        <CardInfo label="Montant HT" value={amountHtLabel} flags={[CardInfoFlag.QUANTITY]} />
      </CardInfosWrapper>
    </CardRow>
  );
};

export const ApproInvoicesRowByFamily: React.FC<IApproInvoicesRow> = ({data}: any) => {
  const subRows = (
    <>
      {data.products.map((product: any) => (
        <Accordion
          key={product.description}
          head={({onClick, toggleIcon}) => <SubRow product={product} onClick={onClick} toggleIcon={toggleIcon} />}
        >
          {product.invoices.map((i: any, key: number) => (
            <SubSubRow product={product} invoice={i} key={key} />
          ))}
        </Accordion>
      ))}
    </>
  );

  return (
    <Card>
      <CardRow>
        <CardTitleContainer>
          <CardTitle title={data.familyName} />
        </CardTitleContainer>
      </CardRow>
      {subRows}
    </Card>
  );
};
