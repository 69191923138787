import React, {useMemo, useState, useEffect, createContext, useContext} from 'react';
import {observer} from 'mobx-react-lite';
import {useStore} from '@stores/index';
import Button from '@components/UI/Button/Button';
import Select from '@components/NewFields/Select';
import {IApproOrdersFilter, fetchApproOrders} from '@services/navagri';
import usePdfGenerator from '@hooks/usePdfGenerator';
import useCsvGenerator from '@hooks/useCsvGenerator';
import {format} from 'date-fns';
import Pagination from '@components/Pagination';
import usePagination from '@hooks/usePagination';
import {getNoDataMessage} from '@utils/tables';
import NoData from '@components/NoData/NoData';
import Loader from '@components/Loader';
import {Formik, useFormikContext} from 'formik';
import FiltersCard from '@components/UI/FiltersCard/FiltersCard';
import QuickHelp from '@components/UI/QuickHelp/QuickHelp';
import Input from '@components/NewFields/Input';
import Search from '@components/Icons/Search';
import TagsContainer from '@components/UI/TagsContainer/TagsContainer';
import Tag from '@components/UI/Tag/Tag';
import OptionModal from '@components/UI/OptionModal/OptionModal';
import CheckBoxAllSelector from '@components/NewFields/CheckBoxAllSelector';
import CheckBox from '@components/NewFields/CheckBox';
import useEffectNotOnFirstRender from '@hooks/useEffectNotOnFirstRender';
import ExportButtons from '@components/UI/ExportButtons/ExportButtons';
import useModal from '@hooks/useModal';
import {FiltersButton} from '@components/UI/FiltersButton/FiltersButton';
import {useWindowSize} from '@hooks/useWindowSize';
import {OrdersRows} from '@components/Orders/OrdersRow/OrdersRows';
import {isNull, isUndefined} from '@lepicard/utils';
import {useRerenderSafeDebounce} from '@hooks/useRerenderSafeDebounce';
import {csvColumns, getCsvFileName, headerValue, pdfCol, pdfSubCol} from './column';
import EnvironmentService from '@utils/environmentService';

interface IOrderListFormContext {
  formValues: any;
  refreshFormValues: (newValues: any) => void;
  onDownloadPdf: () => void;
  onDownloadCsv: () => void;
}

const OrdersListFormContext = createContext<IOrderListFormContext>({
  formValues: {},
  refreshFormValues: () => {
    return;
  },
  onDownloadCsv: () => {
    return;
  },
  onDownloadPdf: () => {
    return;
  },
});

const groupByOption = [
  {
    label: 'Par commandes',
    value: 'order',
  },
  {
    label: 'Par articles',
    value: 'product',
  },
  {
    label: EnvironmentService.getInstanceConfig().orderList.groupByOption.itemFamilyCode,
    value: 'itemFamilyCode',
  },
];

const tagsOptions = [
  {label: 'En cours de livraison', value: 'in-delivery'},
  {label: 'Retours', value: 'item-returned'},
];

const OrderListFilters: React.FC = observer(() => {
  const {refreshFormValues} = useContext(OrdersListFormContext);
  const {approOrdersStore} = useStore();
  const {values, setFieldValue} = useFormikContext();

  useEffectNotOnFirstRender(() => {
    refreshFormValues(values);
  }, [values]);

  return (
    <div className="w-full mb-5">
      <FiltersCard className="grid grid-cols-1 lg:grid-cols-3 gap-x-7 gap-y-4 py-5 px-3.5 lg:py-6 lg:px-4 mb-7">
        <div className="flex-grow flex gap-3">
          <div className="flex items-center min-w-[110px] lg:min-w-min">
            <label htmlFor="campaign" className="mr-1 lg:mr-2.5">
              Exercice
            </label>
            <QuickHelp>Période du 1er juillet au 30 juin</QuickHelp>
          </div>
          <Select
            name="campaignId"
            options={[{label: 'Tous', value: 'all'}, ...approOrdersStore.getApproOrdersCampaigns]}
          />
        </div>
        <div className="flex-grow flex gap-3">
          <div className="flex items-center min-w-[110px] lg:min-w-min">
            <label htmlFor="species" className="mr-1 lg:mr-2.5">
              Grouper
            </label>
          </div>
          <Select name="groupBy" options={groupByOption} placeholder="Tous" />
        </div>
      </FiltersCard>
      <OrderListAdvancedFilters />
    </div>
  );
});

const OrderListAdvancedFiltersTags: React.FC = () => {
  const {setFieldValue, values} = useFormikContext<any>(); // TODO: add type

  return (
    <TagsContainer>
      {tagsOptions.map((tag) => (
        <Tag
          key={tag.value}
          label={tag.label}
          onClick={() => setFieldValue('tagsOption', values.tagsOption === tag.value ? 'all' : tag.value)}
          active={values.tagsOption === tag.value}
        />
      ))}
    </TagsContainer>
  );
};

const OrderListAdvancedFilters: React.FC = () => {
  const {windowWidth} = useWindowSize();

  const Component = windowWidth > 1024 ? OrderListAdvancedFiltersDesktop : OrderListAdvancedFiltersMobile;
  return (
    <div className="mb-4">
      <Component />
    </div>
  );
};

const OrderListAdvancedFiltersMobile: React.FC = observer(() => {
  const {approOrdersStore} = useStore();
  const {onDownloadPdf, onDownloadCsv, formValues} = useContext(OrdersListFormContext);
  const {active, toggle} = useModal();

  return (
    <>
      <div className="flex justify-between gap-5">
        <FiltersButton onClick={toggle} />
      </div>
      <Input
        name="search"
        placeholder="Rechercher par document ou article"
        type="text"
        className="my-4"
        iconAfter={<Search />}
      />
      {formValues.groupBy === 'order' && <ExportButtons onPdfClick={onDownloadPdf} onCsvClick={onDownloadCsv} />}
      <OrderListAdvancedFiltersTags />

      <OptionModal
        active={active}
        title="Filtres"
        toggle={toggle}
        actions={<Button label="Fermer" onClick={toggle} className="w-full" />}
      >
        <h4 className="text-title-4 text-green-100 mb-3.5">Statut :</h4>
        <CheckBoxAllSelector
          name="status"
          options={approOrdersStore.getApproStatusOptions}
          label="Tous"
          className={'mb-7'}
        />
        {approOrdersStore.getApproStatusOptions.map(({value, label}, idx) => {
          return <CheckBox key={value} name="status" label={label} value={value} />;
        })}

        <hr className="hr-m" />
        <h4 className="text-title-4 text-green-100 mb-3.5">Famille d'articles :</h4>
        <CheckBoxAllSelector
          name="itemFamilyCode"
          options={approOrdersStore.getItemFamilyCodeOptions}
          label="Tous"
          className={'mb-7'}
        />
        {approOrdersStore.getItemFamilyCodeOptions.map(({value, label}, idx) => (
          <CheckBox key={idx} name="itemFamilyCode" label={label} value={value} />
        ))}
      </OptionModal>
    </>
  );
});

const OrderListAdvancedFiltersDesktop: React.FC = observer(() => {
  const {approOrdersStore} = useStore();
  const {onDownloadPdf, onDownloadCsv, formValues} = useContext(OrdersListFormContext);

  return (
    <>
      <div className="flex justify-between gap-5 mb-4">
        <Input
          name="search"
          placeholder="Rechercher par document ou article"
          type="text"
          className="h-10 hidden lg:block max-w-xl"
          iconAfter={<Search />}
        />
        {formValues.groupBy === 'order' && <ExportButtons onPdfClick={onDownloadPdf} onCsvClick={onDownloadCsv} />}
      </div>
      <div className="flex justify-between gap-4 ">
        <div className="flex gap-2 grow flex-shrink-[9999]">
          <Select
            label="Statut"
            name="status"
            multiple={true}
            placeholder="Tous"
            options={approOrdersStore.getApproStatusOptions}
            selectAllOption="Tous"
          />
          <Select
            label="Famille d'articles"
            name="itemFamilyCode"
            multiple={true}
            placeholder="Tous"
            options={approOrdersStore.getItemFamilyCodeOptions}
            selectAllOption="Tous"
          />
        </div>
        <OrderListAdvancedFiltersTags />
      </div>
    </>
  );
});

const OrdersList: React.FC = observer(() => {
  const {approOrdersStore, campaigns: campaingsStore} = useStore();

  const {currentCampaign} = campaingsStore;

  const list = approOrdersStore.list.toJSON();

  const initialFormValues = {
    campaignId: approOrdersStore.getApproOrdersCampaigns.some((option) => option.value === currentCampaign!.id)
      ? currentCampaign!.id
      : 'all',
    groupBy: 'order',
    status: [],
    itemFamilyCode: [],
    search: '',
    tagsOption: 'all',
  };

  const [formValues, setFormValues] = useState(initialFormValues);

  const filters = useMemo<IApproOrdersFilter>(() => {
    const newFilters: IApproOrdersFilter = {};

    if (formValues.campaignId !== 'all') {
      newFilters.campaignId = formValues.campaignId;
    }

    newFilters.groupBy = formValues.groupBy;

    if (formValues.tagsOption !== 'all') {
      newFilters.tagsOption = formValues.tagsOption;
    }

    if (formValues.search !== '') {
      newFilters.search = formValues.search;
    }

    if (formValues.status.length >= 0) {
      newFilters.status = formValues.status;
    }

    if (formValues.itemFamilyCode.length >= 0) {
      newFilters.itemFamilyCode = formValues.itemFamilyCode;
    }

    return newFilters;
  }, [formValues]);

  const loadDataToExport = async () => {
    const {data} = await fetchApproOrders({$limit: Number.MAX_SAFE_INTEGER, ...filters, $skip: 0});
    return data;
  };

  const {isPending: isPdfPending, download: downloadPdf} = usePdfGenerator(pdfCol, pdfSubCol);
  const {isPending: isCsvPending, download: downloadCsv} = useCsvGenerator(csvColumns);

  const onDownloadPdf = () => {
    downloadPdf({
      loadDatas: loadDataToExport,
      title: `APPRO. - MES COMMANDES`,
      fileName: `Appro_commandes_${format(new Date(), `dd/MM/yyyy`)}`,
      pdfType: 'Appro',
      subtitle: 'orders',
      headerValue: headerValue(formValues, groupByOption, tagsOptions, approOrdersStore),
    });
  };

  const onDownloadCsv = () => {
    downloadCsv({
      loadDatas: loadDataToExport,
      fileName: `Export_Lepicard_Appro_commandes_${getCsvFileName(formValues, tagsOptions)}`,
      headerValue: headerValue(formValues, groupByOption, tagsOptions, approOrdersStore),
    });
  };

  const loadData = (page: number) => {
    approOrdersStore.fetchOrders(page, filters);
  };

  const {pageNumber, onPageChange, updatePagination} = usePagination(loadData);

  const fetchOrdersDebounced = useRerenderSafeDebounce(approOrdersStore.fetchOrders, 500);
  useEffect(() => {
    fetchOrdersDebounced(1, filters);
    updatePagination(1);
  }, [filters]);

  const displayLoader = ['initial', 'error', 'pending'].includes(approOrdersStore.fetchOrdersState.state);

  return (
    <OrdersListFormContext.Provider
      value={{
        formValues: formValues,
        refreshFormValues: (newValues) => setFormValues(newValues),
        onDownloadPdf,
        onDownloadCsv,
      }}
    >
      <div>
        <Formik initialValues={formValues} onSubmit={() => {
        }}>
          <OrderListFilters />
        </Formik>

        {displayLoader ? (
          <Loader topMargin />
        ) : (
          <>
            <OrdersRows />

            {(Array.isArray(list) && list.length === 0) || !Array.isArray(list) ? (
              <NoData message={getNoDataMessage(filters, currentCampaign?.id)} />
            ) : (
              <Pagination
                activePage={pageNumber}
                itemsCountPerPage={10}
                totalItemsCount={approOrdersStore.fetchOrdersState.total ?? 0}
                onChange={onPageChange}
              />
            )}
          </>
        )}
      </div>
    </OrdersListFormContext.Provider>
  );
});

const OrdersListContainer: React.FC = observer(() => {
  const {campaigns: campaignsStore, approOrdersStore} = useStore();

  const areCampaignsOptionsLoaded = ['done', 'error'].includes(
    approOrdersStore.fetchOrdersGlobalFiltersOptionsState.state,
  );

  useEffect(() => {
    if (!areCampaignsOptionsLoaded) {
      approOrdersStore.fetchOrdersGlobalFiltersOptions();
    }
  }, []);

  if (isUndefined(campaignsStore.currentCampaign?.id) || !areCampaignsOptionsLoaded) {
    return <Loader topMargin />;
  }

  return <OrdersList />;
});

export default OrdersListContainer;
