import {TAuthConfig, TRefreshTokenExpiredEvent} from 'react-oauth2-code-pkce';
import {ISSOStore} from '@stores/sso/store';

export class SSOConfig {
  static clientId = process.env.SSO_CLIENT_ID as string;
  static baseUrl = process.env.SSO_BASE_URL as string;
  static redirectUri = process.env.SSO_REDIRECT_URI as string;
  static userCheckInterval = parseInt(process.env.SSO_USER_CHECK_INTERVAL as string);

  static tokenUrl = SSOConfig.baseUrl + '/token';
  static authorizeUrl = SSOConfig.baseUrl + '/authorize';
  static scope = ['profile', 'sales', 'payement', 'customer', 'contact', 'extranet'].join(' ');

  public static authConfig(): TAuthConfig {
    return {
      clientId: SSOConfig.clientId,
      authorizationEndpoint: SSOConfig.authorizeUrl,
      tokenEndpoint: SSOConfig.tokenUrl,
      redirectUri: SSOConfig.redirectUri,
      scope: SSOConfig.scope,
      autoLogin: SSOConfig.isAutoLogin(),
      onRefreshTokenExpire: (event: TRefreshTokenExpiredEvent) => {
        window.confirm('Votre session est expirée. Merci de recharger la page pour continuer votre navigation') &&
          event.logIn();
      },
    };
  }

  public static isAutoLogin() {
    if (['/logout', '/activation', '/expired'].includes(window.location.pathname)) {
      return false;
    }
    return localStorage.getItem('ROCP_token') !== null;
  }

  public static getSwitchUrl(currentUser: ISSOStore['user']) {
    return SSOConfig.baseUrl + currentUser?.switchUrl;
  }

  public static getDisconnectionUrl(currentUser: ISSOStore['user']) {
    return SSOConfig.baseUrl + currentUser?.logoutUrl;
  }

  public static async fetchSSOInformation(token: string) {
    const myHeaders = new Headers();
    myHeaders.append('Authorization', 'Bearer ' + token);
    const requestOptions: RequestInit = {
      method: 'GET',
      headers: myHeaders,
      redirect: 'follow',
    };

    return fetch(SSOConfig.baseUrl + '/api/me', requestOptions)
      .then((response) => {
        if (!response.ok) {
          throw new Error('Cannot get user information through api/me');
        }
        return response.json();
      })
      .then((body) => {
        return body;
      });
  }
}

export const tokenIsDefined = (token: string) => {
  return token.length > 2;
};
