import {ServiceFdsModel} from './models';
import {types, flow} from 'mobx-state-tree';
import {fetchFDSItems, fetchSalesShipmentLines, IFDSFilter} from '@services/navagri';
import {getPaginationParams} from '@utils/tables';
import views from './views';
import {ConcurrentHelper} from '@utils/ConcurrentHelper';

export const ServiceFdsStore = types.optional(
  ServiceFdsModel.views(views).actions((self) => {
    return {
      fetchFdsItems: flow(function* (page: number, filters?: IFDSFilter) {
        const serviceState = self.fetchFdsState;
        serviceState.pending();

        try {
          const {isLastAction} = ConcurrentHelper.setAction('fetchFDSItems');
          const {data, total, limit, descriptions, manufacturerCode} = yield fetchFDSItems({
            ...getPaginationParams(page),
            ...filters,
          });
          if (!isLastAction()) {
            return;
          }
          self.list = data;

          self.descriptionsList = descriptions;

          self.manufacturerCodeList = manufacturerCode;

          serviceState.setPagination(total, limit);
          serviceState.done();
        } catch (error) {
          console.log('desc', error);

          serviceState.fail({error});
        }
      }),
      fetchCustomerCampaigns: flow(function* () {
        const serviceState = self.fetchCampaignsState;
        serviceState.pending();
        try {
          const {isLastAction} = ConcurrentHelper.setAction('fetchCustomerCampaigns');
          const {campaigns} = yield fetchSalesShipmentLines({});
          if (!isLastAction()) {
            return;
          }

          self.campaigns = campaigns;
          serviceState.done();
        } catch (error) {
          serviceState.fail({error});
        }
      }),
    };
  }),
  {}
);
