import React, {useContext, useState} from 'react';
import {Formik, FormikProps} from 'formik';
import ActivationForm, {IActivationFields, initialValuesActivationField} from '@components/Forms/ActivationForm';
import validate from '@components/Forms/ActivationForm/validate';
import './styles.scss';
import {useLocation} from 'react-router';
import {observer} from 'mobx-react-lite';
import {useStore} from '@stores/index';
import {AuthContext, IAuthContext} from 'react-oauth2-code-pkce';

const Activation = observer(({}) => {
  const {logIn, logOut, error}: IAuthContext = useContext(AuthContext);

  const {activation: activationStore} = useStore();
  const {hasBeenCreated} = activationStore;

  const location = useLocation();
  const query = new URLSearchParams(location.search);

  const onSubmit = (data: IActivationFields) => {
    activationStore.activate(data).then((response) => {
      console.log('success', response);
    });
  };

  const activationCode = query.get('code');

  return (
     (
      <>
        <div className="SpineBubble-content">
          <div className="BubbleContent">
            {!hasBeenCreated && (
              <>
                <div className="BubbleContent-header">
                  <h1 className="Login-title">Activer mon compte</h1>
                </div>
                <div className="BubbleContent-body">
                  <Formik
                    initialValues={{
                      ...initialValuesActivationField,
                      activationCode: activationCode ? activationCode : '',
                    }}
                    onSubmit={onSubmit}
                    validate={validate}
                    children={(formikProps: FormikProps<IActivationFields>) => <ActivationForm {...formikProps} />}
                  />
                </div>
              </>
            )}

            {hasBeenCreated && (
              <>
                <div className="BubbleContent-header">
                  <h1 className="Login-title">
                    Merci,
                    <br />
                    votre compte est activé
                  </h1>
                </div>
                <div className="BubbleContent-body">
                  <p className="mb-4">Vous pouvez maintenant vous connecter à votre espace extranet</p>
                  <div className="Login-buttons">
                    <button className="btn btn-primary uppercase" onClick={() => logIn()}>
                      Se connecter
                    </button>
                  </div>
                </div>
              </>
            )}
          </div>
        </div>
      </>
    )
  );
});

export default Activation;
