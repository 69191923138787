import React from 'react';
import {types, Instance} from 'mobx-state-tree';
import {persist} from './persist';
import {AccountsStore} from './accounts/store';
import {AdivalorStore} from './adivalor/store';
import {ActivationStore} from '@stores/activation/store';
import {CotationStore} from './cotations/store';
import {ContractsStore} from './contracts/store';
import {CampaignsStore} from './campaigns/store';
import {SpeciesStore} from './species/store';
import {AccountingsStore} from './accounting/store';
import {OrdersStore} from './orders/store';
import {DurabilityStore} from './durability/store';
import {BillsStore} from './bills/store';
import {SoldOrdersStore} from './soldorders/store';
import {ApproDeliveriesStore as OldApproDeliveriesStore} from './approDeliveries/store';
import {OffersStore} from './offers/store';
import {VarietySpeciesStore} from './varietySpecies/store';
import {ApproOrdersStore} from '@stores/appro-orders/store';
import {ServiceFdsStore} from './serviceFds/store';
import {CollectDeliveriesStore} from "@stores/collect-deliveries/store";
import {SSOStore} from "@stores/sso/store";
import {ApproContractsStore} from "@stores/appro-contracts/store";
import {ApproInvoicesStore} from "@stores/appro-invoices/store";
import {ApproDeliveriesStore} from "@stores/appro-deliveries/store";
import {CollectInvoicesStore} from "@stores/collect-invoices/store";

export const RootStore = types
  .model({
    accounts: AccountsStore,
    activation: ActivationStore,
    adivalor: AdivalorStore,
    cotations: CotationStore,
    contracts: ContractsStore,
    collectDeliveries: CollectDeliveriesStore,
    collectInvoicesStore: CollectInvoicesStore,
    campaigns: CampaignsStore,
    species: SpeciesStore,
    accounting: AccountingsStore,
    orders: OrdersStore,
    durability: DurabilityStore,
    bills: BillsStore,
    soldOrders: SoldOrdersStore,
    approDeliveries: OldApproDeliveriesStore,
    serviceFds: ServiceFdsStore,
    offers: OffersStore,
    varietySpecies: VarietySpeciesStore,
    approOrdersStore: ApproOrdersStore,
    sso: SSOStore,
    approContractsStore: ApproContractsStore,
    approInvoicesStore: ApproInvoicesStore,
    approDeliveriesStore: ApproDeliveriesStore,
    hydrated: false, // True when the persisted data are loaded
  })
  .actions((self) => {
    return {
      afterHydration: () => {
        self.hydrated = true;
      },
    };
  });

const initialState = {};

export type TAsyncProgress = 'initial' | 'pending' | 'done' | 'error';

export type IRootStore = Instance<typeof RootStore>

const StoreContext = React.createContext<IRootStore>({} as IRootStore);

export const useStore = () => React.useContext(StoreContext);

interface IStoreProviderProps {
  children: React.ReactNode;
}

const createStore = (): IRootStore => {
  return RootStore.create(initialState);
};

const rootStore = createStore();

persist('rootStore', rootStore, initialState, {
  jsonify: true, // if you use AsyncStorage, this shoud be true
  whitelist: ['accounts'], // only these keys will be persisted
}).then(() => {
  rootStore.afterHydration();
});

export const StoreProvider = ({children}: IStoreProviderProps) => {
  return <StoreContext.Provider value={rootStore}>{children}</StoreContext.Provider>;
};
