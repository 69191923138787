import {ICollectInvoicesStoreModel} from './store';
import {flow} from 'mobx-state-tree';
import {fetchCollectInvoices, ICollectInvoicesFilters} from '@services/navagri';
import {ConcurrentHelper} from '@utils/ConcurrentHelper';
import {getPaginationParams} from '@utils/tables';

export default (self: ICollectInvoicesStoreModel) => {
  return {
    fetchInvoices: flow(function* (page: number, filters?: ICollectInvoicesFilters) {
      const serviceState = self.fetchInvoicesState;
      serviceState.pending();
      try {
        const {isLastAction} = ConcurrentHelper.setAction('fetchInvoices');
        const {total, limit, skip, totalData, data, globalFacets, facets} = yield fetchCollectInvoices({
          ...getPaginationParams(page),
          ...filters,
        });
        if (!isLastAction()) {
          return;
        }
        self.global = totalData;
        self.list = data;
        self.globalFacets = globalFacets;
        self.facets = facets;
        serviceState.setPagination(total, limit);
        serviceState.done();
      } catch (error) {
        console.error(error);
        serviceState.fail({error});
      }
    }),
    fetchCampaigns: flow(function* () {
      const serviceState = self.fetchCampaignsState;
      serviceState.pending();
      try {
        const {isLastAction} = ConcurrentHelper.setAction('CollectInvoices-fetchCampaigns');
        const {total, limit, skip, totalData, data, globalFacets, facets} = yield fetchCollectInvoices({
          $limit: 1, // we get an api error with 0 items
          $skip: 0,
        });
        if (!isLastAction()) {
          return;
        }
        self.globalFacets = {
          availableCampaigns: globalFacets.availableCampaigns,
          availableSubFamilies: [],
          availableSpecies: [],
        };
        serviceState.done();
      } catch (error) {
        console.error(error);
        serviceState.fail({error});
      }
    }),
  };
};
