import {getPaginationParams} from '@utils/tables';
import {fetchContracts, IContractFilters} from '@services/navagri';
import {IContractStoreModel} from './models';
import {flow} from 'mobx-state-tree';
import {ConcurrentHelper} from '@utils/ConcurrentHelper';

export default (self: IContractStoreModel) => {
  return {
    fetchCampaigns: flow(function* () {
      const serviceState = self.fetchCampaignsState;
      serviceState.pending();

      try {
        const {isLastAction} = ConcurrentHelper.setAction('CollectContracts-fetchCampaignsState');
        const {campaigns} = yield fetchContracts({
          $limit: 1, // returns everything otherwise
          $skip: 0,
        });
        if (!isLastAction()) {
          return;
        }
        self.campaigns = campaigns;
        serviceState.done();
      } catch (error) {
        serviceState.fail({error});
      }
    }),

    fetchContracts: flow(function* (page: number, filters?: IContractFilters) {
      const serviceState = self.fetchContractsState;
      serviceState.pending();

      try {
        const {isLastAction} = ConcurrentHelper.setAction('fetchContracts');
        const {data, total, limit, facets, comments} = yield fetchContracts({...getPaginationParams(page), ...filters});
        if (!isLastAction()) {
          return;
        }
        self.list = data;
        self.facets = facets;
        serviceState.setPagination(total, limit);
        serviceState.done();
      } catch (error) {
        serviceState.fail({error});
      }
    }),

    fetchSpeciesAndCampaignsContracts: flow(function* (filters?: IContractFilters) {
      const serviceState = self.fetchContractCampaignsState;
      serviceState.pending();
      try {
        const {isLastAction} = ConcurrentHelper.setAction('fetchSpeciesAndCampaignsContracts');
        const {campaigns, species, comments} = yield fetchContracts({...getPaginationParams(1), ...filters});
        if (!isLastAction()) {
          return;
        }

        self.comments = comments;
        self.campaigns = campaigns;
        self.species = species;

        serviceState.done();
      } catch (error) {
        console.log({error});
        serviceState.fail({error});
      }
    }),
  };
};
