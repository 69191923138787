import {Arrow} from '@components/Icons/Arrow';
import {Card} from '@components/UI/Card/Card';
import {CardInfo, CardInfoFlag} from '@components/UI/Card/CardInfo';
import {CardInfosWrapper} from '@components/UI/Card/CardInfosWrapper';
import {CardRow, CardRowType} from '@components/UI/Card/CardRow';
import {CardSeparator} from '@components/UI/Card/CardSeparator';
import {CardShowMore} from '@components/UI/Card/CardShowMore';
import {CardTitle} from '@components/UI/Card/CardTitle';
import {CardTitleContainer} from '@components/UI/Card/CardTitleContainer';
import {Progressbar, ProgressbarTheme} from '@components/UI/Progressbar/Progressbar';
import React, {useState} from 'react';
import {ApproContractsAllStatusTag} from '@components/ApproContracts/ApproContractsStatusTag';
import {CardSubTitle} from '@components/UI/Card/CardSubTitle';
import {CardInfoGroup} from '@components/UI/Card/CardInfoGroup';
import {AlertNotification, AlertTheme} from '@components/UI/AlertNotification/AlertNotification';
import {getShowMoreProductLabel} from '@components/Orders/OrdersRow/OrdersRows';
import {IApproContractsRow} from '@components/ApproContracts/ApproContractsRow/ApproContractsRows';
import {formatDate} from '@utils/string';
import {formatNumber} from '@utils/number';
import {getRemainingDays, getRemainingDaysLabel} from '@utils/date';

interface IApproContractsSubRow {
  contract: any;
  product: any;
}

const ApproContractsSubRow: React.FC<IApproContractsSubRow> = ({product}) => {
  const restToDeliverLabel = formatNumber(product.restToDeliver as number)
    .round()
    .spaces()
    .renderWithUnit('/');
  const totalQuantityLabel = formatNumber(product.quantity as number)
    .round()
    .spaces()
    .renderWithUnit(product.unitOfMeasureCode);
  const fullRestToDeliverLabel = `${restToDeliverLabel}\u00A0${totalQuantityLabel}`;

  const quantityLabel = formatNumber(product.quantity as number)
    .round()
    .spaces()
    .renderWithUnit(product.unitOfMeasureCode);
  const unitPriceLabel = formatNumber(product.netUnitPrice as number)
    .fixed()
    .spaces()
    .renderWithUnit('€');
  const fullNetUnitPriceLabel = `${unitPriceLabel}\u00A0/\u00A0${product.unitOfMeasureCode}`;
  const amountLabel = formatNumber(product.amountIncludingVAT as number)
    .fixed()
    .spaces()
    .renderWithUnit('€');

  return (
    <CardRow type={CardRowType.SUB}>
      <CardSubTitle title={product.description} subTitle={product.familyName} />
      <CardSeparator />
      <CardInfosWrapper>
        <CardInfoGroup>
          <CardInfo label="Quantité" value={quantityLabel} flags={[CardInfoFlag.IMPORTANT, CardInfoFlag.QUANTITY]} />
          <CardInfo label="Prix unitaire HT" value={fullNetUnitPriceLabel} flags={[CardInfoFlag.QUANTITY]} />
        </CardInfoGroup>
        <CardSeparator className="lg:hidden" />
        <CardInfoGroup>
          <CardInfo label="Montant TTC" value={amountLabel} flags={[CardInfoFlag.IMPORTANT, CardInfoFlag.QUANTITY]} />
        </CardInfoGroup>
        <CardSeparator className="lg:hidden" />
        <CardInfo
          label="Reste à livrer"
          value={fullRestToDeliverLabel}
          flags={[CardInfoFlag.IMPORTANT, CardInfoFlag.QUANTITY, CardInfoFlag.END_OF_LINE]}
        />
      </CardInfosWrapper>
    </CardRow>
  );
};

const ApproContractsSubRowPreview: React.FC<IApproContractsSubRow> = ({product}) => {
  const restToDeliverLabel = formatNumber(product.restToDeliver as number)
    .round()
    .spaces()
    .renderWithUnit('/');
  const totalQuantityLabel = formatNumber(product.quantity as number)
    .round()
    .spaces()
    .renderWithUnit(product.unitOfMeasureCode);
  const fullRestToDeliverLabel = `${restToDeliverLabel}\u00A0${totalQuantityLabel}`;

  return (
    <CardRow type={CardRowType.SUB}>
      <div className="lg:flex lg:justify-between lg:items-end">
        <div className="mb-3 lg:mb-0">
          <CardSubTitle title={product.description} subTitle={product.familyName} />
        </div>
        <CardInfo
          label="Reste à livrer"
          value={fullRestToDeliverLabel}
          flags={[CardInfoFlag.QUANTITY, CardInfoFlag.IMPORTANT, CardInfoFlag.END_OF_LINE]}
        />
      </div>
    </CardRow>
  );
};

export const ApproContractsRowByContract: React.FC<IApproContractsRow> = ({data}) => {
  const [isOpen, setIsOpen] = useState(false);
  const showMoreIcon = <Arrow direction={isOpen ? 'top' : 'bottom'} color="#0F4C36" />;

  const totalAmountLabel = formatNumber(data.totalAmountIncludingVAT as number)
    .fixed()
    .spaces()
    .renderWithUnit('€');

  const isContractClosed = data.status.includes('sold');
  const warningRemainingDays = getRemainingDays(data.contractEndingDate);
  const showWarningRemainingDays = !isContractClosed && warningRemainingDays <= 31 && warningRemainingDays >= 0;

  return (
    <Card>
      <CardRow>
        <CardTitleContainer>
          <CardTitle title={`CONTRAT ${data.no}`} subTitle={formatDate(data.date)} />
          <div className="Card-tags">
            <ApproContractsAllStatusTag apiStatus={data.status} />
          </div>
        </CardTitleContainer>
      </CardRow>
      <CardRow>
        <CardInfosWrapper>
          <CardInfo
            label="Début et fin de période de contractualisation"
            value={`${formatDate(data.contractStartingDate)} - ${formatDate(data.contractEndingDate)}`}
            flags={[CardInfoFlag.DOUBLE_WIDTH]}
          />
          {showWarningRemainingDays && (
            <AlertNotification
              theme={warningRemainingDays <= 15 ? AlertTheme.RED : AlertTheme.YELLOW}
              label={`Fin de contrat : ${getRemainingDaysLabel(data.contractEndingDate)}`}
              className="mt-2 lg:mt-0"
            />
          )}
          <CardSeparator />
          <CardInfo label="Montant TTC" value={totalAmountLabel} flags={[CardInfoFlag.QUANTITY]} />
          <CardSeparator className="lg:hidden" />
          <CardInfo
            label="Pourcentage livré"
            value={<Progressbar percent={data.deliveredPercentage} theme={ProgressbarTheme.SOFT} />}
            flags={[CardInfoFlag.PROGRESSBAR, CardInfoFlag.END_OF_LINE]}
          />
        </CardInfosWrapper>
      </CardRow>
      {data.products.map((product: any, key: number) => {
        const Comp = isOpen ? ApproContractsSubRow : ApproContractsSubRowPreview;
        return <Comp key={key} contract={data} product={product} />;
      })}
      <CardShowMore
        toggleIcon={showMoreIcon}
        onClick={() => {
          setIsOpen(!isOpen);
        }}
      >
        {getShowMoreProductLabel(isOpen, data.products.length)}
      </CardShowMore>
    </Card>
  );
};
