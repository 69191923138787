import { fetchVarietySpecies, ISpeciesFilters } from '@services/navagri'
import { IVarietySpeciesStoreModel } from './models'
import { flow } from 'mobx-state-tree'

export default (self: IVarietySpeciesStoreModel) => {
  return {
    fetchVarietySpecies: flow(function* (filters?: ISpeciesFilters) {
      const serviceState = self.fetchVarietySpeciesState
      serviceState.pending()

      try {
        const { data, subfamilies } = yield fetchVarietySpecies(filters)

        self.list = data
        self.subfamilyList = subfamilies

        serviceState.done()
      } catch (error) {

        console.log('error', error)
        serviceState.fail({error})
      }
    })
  }
}
