import React from 'react';
import {ApproContractsRowByContract} from './ApproContractsRowByContract';
import {ApproContractsRowByFamily} from './ApproContractsRowByFamily';
import {ApproContractsRowByProduct} from '@components/ApproContracts/ApproContractsRow/ApproContractsRowByProduct';
import {useStore} from '@stores/index';
import {isUndefined} from '@lepicard/utils';
import {CardList} from '@components/UI/Card/CardList';

export interface IApproContractsRow {
  data: any;
}

export const ApproContractsRows: React.FC = () => {
  const {approContractsStore} = useStore();
  const list = approContractsStore.list.toJSON();

  return (
    <CardList>
      {list.map((data) => {
        if (!isUndefined(data.no)) {
          return <ApproContractsRowByContract key={data.no} data={data} />;
        }
        if (!isUndefined(data.description)) {
          return <ApproContractsRowByProduct key={data.description} data={data} />;
        }
        if (!isUndefined(data.familyCode)) {
          return <ApproContractsRowByFamily key={data.familyCode} data={data} />;
        }
        return null;
      })}
    </CardList>
  );
};
