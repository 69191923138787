import React, {useCallback} from 'react';
import {Tabs, TabList, Tab, TabPanel} from 'react-tabs';
import AccountingView from '@components/Accounting/AccountingView';
import {useStore} from '@stores/index';
import {IAccountingFilters} from '@services/navagri';
import {fetchCustomerAccounts, fetchSupplierAccounts} from '@services/navagri';
import './styles.scss';
import EnvironmentService from '@utils/environmentService';

const Accounting: React.FC = () => {
  const {accounting: accountingStore} = useStore();

  const loadData = (type: 'customer' | 'supplier') => (page: number, filters: IAccountingFilters) => {
    accountingStore.fetchAccounting(type, page, filters);
  };

  const loadDataToExport = (isCustomer: boolean) => async (filters: IAccountingFilters) => {
    const {data} = isCustomer
      ? await fetchCustomerAccounts({...filters, $limit: Number.MAX_SAFE_INTEGER, skip: 0})
      : await fetchSupplierAccounts({...filters, $limit: Number.MAX_SAFE_INTEGER, skip: 0});

    const linkedItems: string[] = [];
    const filteredData = [];
    for (const row of data) {
      const isLinkedItem = !!row.linked.num;

      if (!linkedItems.includes(row.num)) {
        filteredData.push(row);

        // Dédoublonnage des paiements pour le PDF {Paiement A + Facture A, Paiement A + Facture B} > {Paiement A}
        if (isLinkedItem) {
          linkedItems.push(row.num);
        }
      }
    }

    return filteredData;
  };

  const defaultSelectedTab = EnvironmentService.getInstanceConfig().accounting.selectedTabIndex;

  return (
    <div className="Accounting">
      <Tabs defaultIndex={defaultSelectedTab}>
        <div className="Accounting-head">
          <div className="Accounting-head-bg bg-green-gradient-200"></div>
          <TabList className="Accounting-head-tabList">
            <Tab>
              <div>Mes achats</div>
            </Tab>
            <Tab>
              <div>Mes ventes</div>
            </Tab>
          </TabList>
          <div className="Accounting-head-title">
            <h1 className="text-title-1">Comptabilité client</h1>
          </div>
        </div>

        <TabPanel>
          <AccountingView
            typeLabel="customer"
            loadData={loadData('customer')}
            loadDataToExport={loadDataToExport(true)}
            type="customer"
          />
        </TabPanel>
        <TabPanel>
          <AccountingView
            typeLabel="supplier"
            loadData={loadData('supplier')}
            loadDataToExport={loadDataToExport(false)}
            type="supplier"
          />
        </TabPanel>
      </Tabs>
    </div>
  );
};

export default Accounting;
