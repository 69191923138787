import { fetchSpecies, ISpeciesFilters } from '@services/navagri'
import { ISpeciesStoreModel } from './models'
import { flow } from 'mobx-state-tree'

export default (self: ISpeciesStoreModel) => {
  return {
    fetchSpecies: flow(function* (filters?: ISpeciesFilters) {
      const serviceState = self.fetchSpeciesState
      serviceState.pending()

      try {
        const { data, subfamilies } = yield fetchSpecies(filters)

        self.list = data
        self.subfamilyList = subfamilies

        serviceState.done()
      } catch (error) {
        serviceState.fail({error})
      }
    })
  }
}
