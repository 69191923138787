import {getLabelByValue} from '@utils/pdf';
import {numberToLocaleString} from '@utils/string';
import {renderDateSignOff} from '@utils/tables';
import {isNull} from '@lepicard/utils';
import {formatNumber, preFormattedQuantity} from '@utils/number';
import {ICsvColumn} from '@hooks/useCsvGenerator';

export const csvColumns: ICsvColumn[] = [
  {
    id: 'no',
    Header: 'Contrat N°',
    accessor: 'no',
  },
  {
    id: 'contractStartingDate',
    Header: 'Début de contrat',
    accessor: 'contractStartingDate',
    isDate: true,
  },
  {
    id: 'contractEndingDate',
    Header: 'Fin de contrat',
    accessor: 'contractEndingDate',
    isDate: true,
    render: (column, value) => {
      return `${renderDateSignOff(value, "Pas de date d'échéance")} `;
    },
  },
  {
    id: 'familyName',
    Header: 'Familles',
    accessor: 'familyName',
  },
  {
    id: 'description',
    Header: 'Produits',
    accessor: 'description',
  },
  {
    id: 'amountIncludingVAT',
    Header: 'Montant TTC',
    accessor: 'amountIncludingVAT',
    render: (column, value) => {
      return typeof value === 'number' ? formatNumber(value).fixed().decimalComma().render() : '';
    },
  },
  {
    id: 'quantity',
    Header: 'Quantité totale',
    accessor: 'quantity',
    render: (column, value) => {
      return typeof value === 'number' ? formatNumber(value).fixed().decimalComma().render() : '';
    },
  },
  {
    id: 'shippedQuantity',
    Header: 'Quantité livrée',
    accessor: 'shippedQuantity',
    render: (column, value) => {
      return typeof value === 'number' ? formatNumber(value).fixed().decimalComma().render() : '';
    },
  },
  {
    id: 'netUnitPrice',
    Header: 'Prix unitaire HT',
    accessor: 'netUnitPrice',
    render: (column, value) => {
      return typeof value === 'number' ? formatNumber(value).fixed().decimalComma().render() : '';
    },
  },
  {
    id: 'status',
    Header: 'Statut',
    accessor: 'status',
    render: (column, value) => {
      return value.includes('sold') ? 'Soldé' : 'Non soldé';
    },
  },
];

export const pdfCol = [
  {
    id: 'no',
    Header: 'Contrat N°',
    accessor: 'no',
  },
  {
    id: 'contractStartingDate',
    Header: 'Début de contrat',
    accessor: 'contractStartingDate',
    isDate: true,
  },
  {
    id: 'contractEndingDate',
    Header: 'Fin de contrat',
    accessor: 'contractEndingDate',
    isDate: true,
    render: (column, value) => {
      return `${renderDateSignOff(value, "Pas de date d'échéance")} `;
    },
  },
  {
    id: 'totalAmountIncludingVAT',
    Header: 'Montant TTC',
    accessor: 'totalAmountIncludingVAT',
    render: (column, value) => {
      return typeof value === 'number' ? `${numberToLocaleString(value)} €` : '';
    },
  },
  {
    id: 'deliveredPercentage',
    Header: 'Reste à livrer',
    accessor: 'deliveredPercentage',
    render: (column, value) => {
      return typeof value === 'number' ? `${numberToLocaleString(value)} %` : '';
    },
  },
  {
    id: 'status',
    Header: 'Statut',
    accessor: 'status',
    render: (column, value) => {
      return value.includes('sold') ? 'Soldé' : 'Non soldé';
    },
  },
];

export const pdfSubCol = [
  {
    id: 'familyName',
    Header: 'Familles',
    subAccessor: 'familyName',
  },
  {
    id: 'description',
    Header: 'Produits',
    subAccessor: 'description',
  },
  {
    id: 'amountIncludingVAT',
    Header: 'Montant TTC',
    subAccessor: 'amountIncludingVAT',
    render: (column, value) => {
      return typeof value === 'number' ? `${numberToLocaleString(value)} €` : '';
    },
  },
  {
    id: 'netUnitPrice',
    Header: 'Prix unitaire HT',
    subAccessor: 'netUnitPrice',
    render: (column, value) => {
      return typeof value === 'number' ? `${numberToLocaleString(value)} €` : '';
    },
  },
  {
    id: 'quantity',
    Header: 'Livré/Quantité',
    handleRender(product, contract) {
      const unit = product.unitOfMeasureCode;
      const shippedQuantityLabel = preFormattedQuantity(product.shippedQuantity).renderWithUnit(unit);
      const totalQuantityLabel = preFormattedQuantity(product.quantity).renderWithUnit(unit);
      return `${shippedQuantityLabel} / ${totalQuantityLabel}`;
    },
  },
];

export const headerValue = (formValues, groupByOption, store) => {
  return {
    campaignId: isNull(formValues.campaignId) || formValues.campaignId === 'all' ? '' : formValues.campaignId,
    groupBy: getLabelByValue(formValues.groupBy, groupByOption),
    statut: getLabelByValue(formValues.status, store.getStatusOptions),
    itemFamilyCode: getLabelByValue(formValues.itemFamilyCode, store.getItemFamilyCodeOptions),
    search: formValues.search,
  };
};
