import {IApproDeliveriesStoreModel} from '@stores/appro-deliveries/store';

export default (self: IApproDeliveriesStoreModel) => {
  return {
    get getCampaignsOptions() {
      return self.globalFacets?.availableCampaigns.toJSON().toSorted((a, b) => a.label.localeCompare(b.label)) ?? [];
    },
    get getStatusOptions() {
      return self.facets?.availableStatus.toJSON().toSorted((a, b) => a.label.localeCompare(b.label)) ?? [];
    },
    get getItemFamilyCodeOptions() {
      return self.facets?.availableItemFamilyCode.toJSON().toSorted((a, b) => a.label.localeCompare(b.label)) ?? [];
    },
  };
};
