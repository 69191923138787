import {IApproDeliveriesStoreModel} from './store';
import {flow} from 'mobx-state-tree';
import {fetchApproDeliveries, IApproDeliveriesFilter} from '@services/navagri';
import {ConcurrentHelper} from '@utils/ConcurrentHelper';
import {getPaginationParams} from '@utils/tables';

export default (self: IApproDeliveriesStoreModel) => {
  return {
    fetchDeliveriesGlobalFiltersOptions: flow(function* () {
      const serviceState = self.fetchDeliveriesGlobalFiltersOptionsState;
      serviceState.pending();
      try {
        const {isLastAction} = ConcurrentHelper.setAction('fetchDeliveriesGlobalFiltersOptions');
        const {globalFacets} = yield fetchApproDeliveries({
          $limit: 0,
          $skip: 0,
          groupBy: 'delivery',
        });
        if (!isLastAction()) {
          return;
        }
        self.globalFacets = globalFacets;
        serviceState.done();
      } catch (error) {
        serviceState.fail({error});
      }
    }),
    fetchDeliveries: flow(function* (page: number, filters?: IApproDeliveriesFilter) {
      const serviceState = self.fetchDeliveriesState;
      serviceState.pending();
      try {
        const {isLastAction} = ConcurrentHelper.setAction('fetchDeliveries');
        const {data, globalFacets, facets, total, limit} = yield fetchApproDeliveries({
          ...getPaginationParams(page),
          ...filters,
        });
        if (!isLastAction()) {
          return;
        }
        self.list = data;
        self.globalFacets = globalFacets;
        self.facets = facets;
        serviceState.setPagination(total, limit);
        serviceState.done();
      } catch (error) {
        console.error(error);
        serviceState.fail({error});
      }
    }),
  };
};
