import React, {useState} from 'react';
import {toast} from 'react-toastify';

import {getPdf} from '@services/navagri';
import FileSaver from 'file-saver';
import * as fs from 'fs';
import {defaultErrorToast} from '@utils/toast';
import {toaster} from '@utils/dictionnary';

const useDownloadPdf = () => {
  const [isPending, setIsPending] = useState(false);

  const download = async (value: string, type: number, name: string) => {
    setIsPending(true);

    try {
      const fileData = {
        docId: value,
        documentType: type,
      };
      const downloadToaster = toast.info('Téléchargement en cours', {
        autoClose: false,
        hideProgressBar: true,
      });
      const file = await getPdf(fileData);
      const contentType = 'data:application/pdf;base64';

      const byteCharacters = atob(file);
      const byteNumbers = new Array(byteCharacters.length);
      for (let i = 0; i < byteCharacters.length; i++) {
        byteNumbers[i] = byteCharacters.charCodeAt(i);
      }
      const byteArray = new Uint8Array(byteNumbers);
      const blob = new Blob([byteArray], {type: contentType});
      FileSaver.saveAs(blob, `${name}.pdf`);
      toast.dismiss(downloadToaster);
      toast.update(downloadToaster, {
        render: 'Téléchargement réussi',
        type: 'success',
        autoClose: 1000,
      });
    } catch (error) {
      console.log('error', error);
      defaultErrorToast(toaster.error.download);
    }

    setIsPending(false);
  };

  return {
    download,
    isPending,
  };
};

export default useDownloadPdf;
