import React, {useContext, useEffect} from 'react';
import './styles.scss';
import {observer} from 'mobx-react-lite';
import {AuthContext, IAuthContext} from 'react-oauth2-code-pkce';
import EnvironmentService from '@utils/environmentService';
import {Link} from 'react-router-dom';

const SessionExpired = observer(({}) => {
  const {logIn, logOut, error}: IAuthContext = useContext(AuthContext);
  const instanceConfig = EnvironmentService.getInstanceConfig();

  useEffect(() => {
    logOut();
  }, []);

  return (
    <>
      <div className="SpineBubble-content">
        <div className="BubbleContent">
          <div className="BubbleContent-header">
            <h1 className="Login-title">Déconnexion</h1>
          </div>
          <div className="BubbleContent-body">
            <p className="mb-4">
              <strong>Votre session de connexion a expiré.</strong>
            </p>
            <p className="mb-4">
              Vous pouvez vous reconnecter ou fermer votre fenêtre de navigation
            </p>
          </div>

          <div className="BubbleContent-header">
            <h1 className="Login-title"></h1>
          </div>
          <div className="BubbleContent-body">
            <div className="Login-buttons">
              <button className="btn btn-primary uppercase" onClick={() => logIn()}>
                Se connecter
              </button>
              <Link to="/activation" className="btn btn-primary uppercase">
                Activer votre compte
              </Link>
            </div>
          </div>
        </div>
      </div>
    </>
  );
});

export default SessionExpired;
