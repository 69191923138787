import {getGroupedByPaginationParams} from '@utils/tables';
import {fetchCollectDeliveries, ICollectDeliveriesFilters} from '@services/navagri';
import {ICollectDeliveriesStoreModel} from './models';
import {flow} from 'mobx-state-tree';
import {ConcurrentHelper} from '@utils/ConcurrentHelper';

export default (self: ICollectDeliveriesStoreModel) => {
  return {
    fetchCampaignsDeliveries: flow(function* () {
      const serviceState = self.fetchCampaignsDeliveriesState;

      serviceState.pending();

      try {
        const {isLastAction} = ConcurrentHelper.setAction('fetchCampaignsDeliveries');
        const {campaigns, species} = yield fetchCollectDeliveries({
          $limit: 0,
          $skip: 0,
        });
        if (!isLastAction()) {
          return;
        }
        self.campaigns = campaigns;
        serviceState.done();
      } catch (error) {
        console.log({error});
        serviceState.fail({error});
      }
    }),

    fetchCollectDeliveries: flow(function* (page: number, filters?: ICollectDeliveriesFilters) {
      const serviceState = self.fetchCollectDeliveriesState;

      serviceState.pending();

      try {
        const {isLastAction} = ConcurrentHelper.setAction('fetchCollectDeliveries');
        const response = yield fetchCollectDeliveries({...getGroupedByPaginationParams(page), ...filters});
        if (!isLastAction()) {
          return;
        }
        self.global = response.global;
        self.list = response.data;
        self.facets = response.facets;

        self.campaigns = response.campaigns;
        self.species = response.species;

        serviceState.setPagination(response.total, response.limit);
        serviceState.done();
      } catch (error) {
        console.log({error});
        serviceState.fail({error});
      }
    }),

    fetchSpeciesDeliveries: flow(function* (filters?: ICollectDeliveriesFilters) {
      const serviceState = self.fetchCollectDeliveriesCampaignsState;

      serviceState.pending();

      try {
        const {isLastAction} = ConcurrentHelper.setAction('fetchSpeciesDeliveries');
        const {campaigns, species} = yield fetchCollectDeliveries({...getGroupedByPaginationParams(1), ...filters});
        if (!isLastAction()) {
          return;
        }
        self.species = species;
        serviceState.done();
      } catch (error) {
        console.log({error});
        serviceState.fail({error});
      }
    }),
  };
};
