import {IApproOrdersStoreModel} from '@stores/appro-orders/store';

const statusShipmentLabelMapping: Record<number, string> = {
  0: 'Non expédiée',
  1: 'Partiellement expédiée',
  2: 'Expédiée',
};

const statusInvoiceLabelMapping: Record<number, string> = {
  0: 'Non facturée',
  1: 'Partiellement facturée',
  2: 'Facturée',
};

export default (self: IApproOrdersStoreModel) => {
  return {
    get getApproOrdersCampaigns() {
      return self.campaigns.toSorted((a, b) => a.label.localeCompare(b.label));
    },
    get getApproStatusOptions() {
      return (
        self.facets?.availableStatus
          .toJSON()
          .filter((option) => option.status !== 999_999)
          .map((option) => {
            const labelMapping = option.type === 'shipment' ? statusShipmentLabelMapping : statusInvoiceLabelMapping;
            return {
              label: labelMapping[option.status],
              value: option.value,
            };
          })
          .toSorted((a, b) => a.label.localeCompare(b.label)) ?? []
      );
    },
    get getItemFamilyCodeOptions() {
      return self.facets?.availableItemId.toJSON().toSorted((a, b) => a.label.localeCompare(b.label)) ?? [];
    },
  };
};
