import {types, onSnapshot, flow} from 'mobx-state-tree';
import {IRemoteDurability, postDurability} from '@services/durability';
import {DurabilityStoreModel} from './models';

export function errorHasMessageStringKey(error: unknown): error is {message: string} {
  return typeof error === 'object' && error !== null && 'message' in error && typeof error.message === 'string'
}

export const DurabilityStore = types.optional(
  DurabilityStoreModel.actions((self) => {
    return {
      // flow : asynchronous actions
      postDurability: flow(function* (Durability: IRemoteDurability, file: File | null) {
        const serviceState = self.fetchDurability;
        serviceState.pending();
        try {
          yield postDurability(Durability, file);
          serviceState.done();
        } catch (error) {
          serviceState.fail({
            message: errorHasMessageStringKey(error) ? error.message : undefined, error
          });
        }
      }),
    };
  }),
  {}
);
