import React, {useState} from 'react';
import {Progressbar, ProgressbarTheme} from '@components/UI/Progressbar/Progressbar';
import {CardInfo, CardInfoFlag} from '@components/UI/Card/CardInfo';
import {CardRow, CardRowType} from '@components/UI/Card/CardRow';
import {CardSubTitle} from '@components/UI/Card/CardSubTitle';
import {CardSeparator} from '@components/UI/Card/CardSeparator';
import {CardInfosWrapper} from '@components/UI/Card/CardInfosWrapper';
import {CardInfoGroup} from '@components/UI/Card/CardInfoGroup';
import {CardShowMore} from '@components/UI/Card/CardShowMore';
import {CardRowHeightWrapper, CardRowHeightWrapperType} from '@components/UI/Card/CardRowHeightWrapper';
import {Card} from '@components/UI/Card/Card';
import {CardTitle} from '@components/UI/Card/CardTitle';
import {CardTitleContainer} from '@components/UI/Card/CardTitleContainer';
import {Accordion} from '@components/UI/Accordion/Accordion';
import {Arrow} from '@components/Icons/Arrow';
import {AlertNotification, AlertTheme} from '@components/UI/AlertNotification/AlertNotification';
import {ApproContractsAllStatusTag} from '@components/ApproContracts/ApproContractsStatusTag';
import {IApproContractsRow} from '@components/ApproContracts/ApproContractsRow/ApproContractsRows';
import {formatNumber} from '@utils/number';
import {formatDate} from '@utils/string';
import {getRemainingDays, getRemainingDaysLabel} from '@utils/date';
import {getShowMoreProductLabel} from '@components/Orders/OrdersRow/OrdersRows';

interface IApproContractsSubRow {
  contract: any;
}

interface IOrdersPreviewRow {
  product: any;
  onClick: () => void;
  toggleIcon: React.ReactNode;
}

const ApproContractsPreviewRow: React.FC<IOrdersPreviewRow> = ({onClick, toggleIcon, product}) => {
  const showMoreLabel = `${product.contracts.length} ${product.contracts.length > 1 ? 'contrats' : 'contrat'}`;

  return (
    <CardRow type={CardRowType.SUB_PREVIEW} onClick={onClick}>
      <div className="flex justify-between">
        <CardSubTitle title={product.description} />
        <div className="flex gap-2 items-center">
          <p>{showMoreLabel}</p>
          {toggleIcon}
        </div>
      </div>
    </CardRow>
  );
};

const ApproContractsSubRow: React.FC = ({contract, product}: any) => {
  const startAndEndDates = `${formatDate(contract.contractStartingDate)} - ${formatDate(contract.contractEndingDate)}`;

  const isContractClosed = contract.status.includes('sold');
  const warningRemainingDays = getRemainingDays(contract.contractEndingDate);
  const showWarningRemainingDays = !isContractClosed && warningRemainingDays <= 31 && warningRemainingDays >= 0;

  const restToDeliverLabel = formatNumber(contract.restToDeliver as number)
    .round()
    .spaces()
    .renderWithUnit('/');

  const totalQuantityLabel = formatNumber(contract.quantity as number)
    .round()
    .spaces()
    .renderWithUnit(product.unitOfMeasureCode);
  const fullRestToDeliverLabel = `${restToDeliverLabel}\u00A0${totalQuantityLabel}`;

  const quantityLabel = formatNumber(contract.quantity as number)
    .round()
    .spaces()
    .renderWithUnit(product.unitOfMeasureCode);

  const unitPriceLabel = formatNumber(contract.netUnitPrice as number)
    .fixed()
    .spaces()
    .renderWithUnit('€');
  const fullNetUnitPriceLabel = `${unitPriceLabel}\u00A0/\u00A0${product.unitOfMeasureCode}`;
  const amountLabel = formatNumber(contract.amountIncludingVAT as number)
    .fixed()
    .spaces()
    .renderWithUnit('€');

  return (
    <CardRow type={CardRowType.SUB}>
      <CardTitleContainer>
        <CardTitle title={`CONTRAT ${contract.documentNo}`} />
        <div className="Card-tags">
          <ApproContractsAllStatusTag apiStatus={contract.status} />
        </div>
      </CardTitleContainer>
      <CardSeparator className="lg:hidden" />
      <CardInfosWrapper className="lg:mt-2">
        <CardInfo
          label="Début et fin de période de livraison"
          value={startAndEndDates}
          flags={[CardInfoFlag.DOUBLE_WIDTH]}
        />
        {showWarningRemainingDays && (
          <AlertNotification
            theme={warningRemainingDays <= 15 ? AlertTheme.RED : AlertTheme.YELLOW}
            label={`Fin de contrat : ${getRemainingDaysLabel(contract.contractEndingDate)}`}
            className="mt-2 lg:mt-0"
          />
        )}
      </CardInfosWrapper>
      <CardSeparator />
      <CardInfosWrapper>
        <CardInfoGroup>
          <CardInfo label="Quantité" value={quantityLabel} flags={[CardInfoFlag.IMPORTANT, CardInfoFlag.QUANTITY]} />
          <CardInfo label="Prix unitaire HT" value={fullNetUnitPriceLabel} flags={[CardInfoFlag.QUANTITY]} />
        </CardInfoGroup>
        <CardSeparator className="lg:hidden" />
        <CardInfoGroup>
          <CardInfo label="Montant TTC" value={amountLabel} flags={[CardInfoFlag.QUANTITY, CardInfoFlag.IMPORTANT]} />
        </CardInfoGroup>
        <CardSeparator className="lg:hidden" />
        <CardInfo
          label="Reste à livrer"
          value={fullRestToDeliverLabel}
          flags={[CardInfoFlag.IMPORTANT, CardInfoFlag.QUANTITY, CardInfoFlag.END_OF_LINE]}
        />
      </CardInfosWrapper>
    </CardRow>
  );
};

export const ApproContractsRowByFamily: React.FC<IApproContractsRow> = ({data}) => {
  const [isOpen, setIsOpen] = useState(false);
  const showMoreIcon = <Arrow direction={isOpen ? 'top' : 'bottom'} color="#0F4C36" />;

  const rowWrapperClosedQuantity = 5;

  function onPreviewClick(): void {
    if (!isOpen) {
      setIsOpen(true);
    }
  }

  const totalAmountIncludingVAT = formatNumber(data.totalAmountIncludingVAT as number)
    .fixed()
    .spaces()
    .renderWithUnit('€');

  const displayedProducts = isOpen ? data.products : data.products.slice(0, rowWrapperClosedQuantity);
  const hiddenProductsCount = data.products.length - rowWrapperClosedQuantity;

  const subRows = (
    <>
      {displayedProducts.map((product) => (
        <Accordion
          key={product.description}
          head={({onClick, toggleIcon}) => (
            <ApproContractsPreviewRow
              product={product}
              onClick={() => {
                onPreviewClick();
                onClick();
              }}
              toggleIcon={toggleIcon}
            />
          )}
        >
          {product.contracts.map((contract: any, key: number) => (
            <ApproContractsSubRow contract={contract} product={product} key={key} />
          ))}
        </Accordion>
      ))}
    </>
  );

  return (
    <Card>
      <CardRow>
        <CardTitleContainer>
          <CardTitle title={data.familyName} />
        </CardTitleContainer>
      </CardRow>
      <CardRow>
        <CardInfosWrapper>
          <CardInfo label="Montant TTC" value={totalAmountIncludingVAT} flags={[CardInfoFlag.QUANTITY]} />
          <CardSeparator className="lg:hidden" />
          <CardInfo
            label="Pourcentage livré"
            value={<Progressbar percent={data.deliveredPercentage} theme={ProgressbarTheme.SOFT} />}
            flags={[CardInfoFlag.PROGRESSBAR, CardInfoFlag.END_OF_LINE]}
          />
        </CardInfosWrapper>
      </CardRow>
      {data.products.length > rowWrapperClosedQuantity ? (
        <CardRowHeightWrapper type={CardRowHeightWrapperType.LOCK} active={!isOpen}>
          {subRows}
        </CardRowHeightWrapper>
      ) : (
        subRows
      )}
      {data.products.length - rowWrapperClosedQuantity > 0 && (
        <CardShowMore
          toggleIcon={showMoreIcon}
          onClick={() => {
            setIsOpen(!isOpen);
          }}
        >
          {getShowMoreProductLabel(isOpen, hiddenProductsCount)}
        </CardShowMore>
      )}
    </Card>
  );
};
