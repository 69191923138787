import React, {useMemo, useState} from 'react';
import {Progressbar, ProgressbarTheme} from '@components/UI/Progressbar/Progressbar';
import {CardInfo, CardInfoFlag} from '@components/UI/Card/CardInfo';
import {CardRow, CardRowType} from '@components/UI/Card/CardRow';
import {CardSubTitle} from '@components/UI/Card/CardSubTitle';
import {CardSeparator} from '@components/UI/Card/CardSeparator';
import {CardInfosWrapper} from '@components/UI/Card/CardInfosWrapper';
import {CardInfoGroup} from '@components/UI/Card/CardInfoGroup';
import {CardShowMore} from '@components/UI/Card/CardShowMore';
import {Card} from '@components/UI/Card/Card';
import {CardTitleContainer} from '@components/UI/Card/CardTitleContainer';
import {CardTitle} from '@components/UI/Card/CardTitle';
import {Arrow} from '@components/Icons/Arrow';
import {formatDate} from '@utils/string';
import useDownloadPdf from '@hooks/useDownloadPdf';
import {getShowMoreProductLabel, OrdersRowsCardTags} from '@components/Orders/OrdersRow/OrdersRows';
import OrdersStatusTag, {OrdersStatusEnum} from '@components/Orders/OrdersStatusTag';
import {getTransformedDisplayedLines} from '@components/Orders/OrdersRow/OrdersRowShared';
import {formatNumber} from '@utils/number';

interface IOrdersSubRow {
  order: any;
  item: any;
  attachedLines: any[];
}

const OrdersSubRowPreview: React.FC<IOrdersSubRow> = ({item, order}) => {
  const unitLabel = `\u00A0${item.quantityUnit}`;
  const totalQuantityLabel = formatNumber(item.quantity as number)
    .round()
    .spaces()
    .renderWithUnit(item.quantityUnit);
  const restToDeliverLabel = `${formatNumber(item.restToDeliver as number)
    .round()
    .spaces()
    .renderWithUnit('/')}\u00A0${totalQuantityLabel}`;

  return (
    <CardRow type={CardRowType.SUB}>
      <div className="lg:flex lg:justify-between lg:items-end">
        <div className="mb-3 lg:mb-0">
          <CardSubTitle title={item.description} subTitle={item.familyName} />
        </div>
        <CardInfo
          label="Reste à livrer"
          value={restToDeliverLabel}
          flags={[CardInfoFlag.QUANTITY, CardInfoFlag.IMPORTANT, CardInfoFlag.END_OF_LINE]}
        />
      </div>
    </CardRow>
  );
};

const OrdersSubRowPreviewSimple: React.FC<IOrdersSubRow> = ({item, order}) => {
  return (
    <CardRow type={CardRowType.SUB}>
      <div className="lg:flex lg:justify-between lg:items-end">
        <div className="mb-3 lg:mb-0">
          <CardSubTitle title={item.description} />
        </div>
      </div>
    </CardRow>
  );
};

const OrdersSubRowPreviewReturn: React.FC<IOrdersSubRow> = ({item, order}) => {
  return (
    <CardRow type={CardRowType.SUB}>
      <div className="lg:flex lg:justify-between lg:items-end">
        <div className="mb-3 lg:mb-0">
          <CardSubTitle title={item.description} subTitle={item.familyName} />
        </div>
        <OrdersStatusTag status={OrdersStatusEnum.RETURNED} />
      </div>
    </CardRow>
  );
};

const OrdersSubRowPreviewReturnSimple: React.FC<IOrdersSubRow> = ({item, order}) => {
  return (
    <CardRow type={CardRowType.SUB}>
      <div className="lg:flex lg:justify-between lg:items-end">
        <div className="mb-3 lg:mb-0">
          <CardSubTitle title={item.description} />
        </div>
        <OrdersStatusTag status={OrdersStatusEnum.RETURNED} />
      </div>
    </CardRow>
  );
};

const OrdersSubRow: React.FC<IOrdersSubRow> = ({item, attachedLines}) => {
  const unitLabel = `\u00A0${item.quantityUnit}`;
  const totalQuantityLabel = formatNumber(item.quantity as number)
    .round()
    .spaces()
    .renderWithUnit(item.quantityUnit);
  const restToDeliverLabel = `${formatNumber(item.restToDeliver as number)
    .round()
    .spaces()
    .renderWithUnit('/')}\u00A0${totalQuantityLabel}`;
  const totalPriceLabel = formatNumber(item.amountIncludingVAT as number)
    .fixed()
    .spaces()
    .renderWithUnit('€');
  const unitPriceLabel = `${formatNumber(item.netUnitPrice as number)
    .fixed()
    .spaces()
    .renderWithUnit('€')}\u00A0/${unitLabel}`;

  return (
    <CardRow type={CardRowType.SUB}>
      <CardSubTitle title={item.description} subTitle={item.familyName} />
      <CardSeparator />
      <CardInfosWrapper>
        <CardInfoGroup>
          <CardInfo
            label="Quantité"
            value={totalQuantityLabel}
            flags={[CardInfoFlag.IMPORTANT, CardInfoFlag.QUANTITY]}
          />
          <CardInfo label="Prix unitaire HT" value={unitPriceLabel} flags={[CardInfoFlag.QUANTITY]} />
        </CardInfoGroup>
        <CardSeparator className="lg:hidden" />
        <CardInfoGroup>
          <CardInfo
            label="Montant TTC"
            value={totalPriceLabel}
            flags={[CardInfoFlag.IMPORTANT, CardInfoFlag.QUANTITY]}
          />
          {attachedLines.map((line, key) => (
            <CardInfo
              key={key}
              label={line.description}
              value={formatNumber(line.amountIncludingVAT as number)
                .fixed()
                .spaces()
                .renderWithUnit('€')}
              flags={[CardInfoFlag.QUANTITY]}
            />
          ))}
        </CardInfoGroup>
        <CardSeparator className="lg:hidden" />
        <CardInfo
          label="Reste à livrer"
          value={restToDeliverLabel}
          flags={[CardInfoFlag.IMPORTANT, CardInfoFlag.QUANTITY, CardInfoFlag.END_OF_LINE]}
        />
      </CardInfosWrapper>
    </CardRow>
  );
};

const OrdersSubRowSimple: React.FC<IOrdersSubRow> = ({item}) => {
  const totalPriceLabel = formatNumber(item.amountIncludingVAT as number)
    .fixed()
    .spaces()
    .renderWithUnit('€');

  return (
    <CardRow type={CardRowType.SUB}>
      <CardSubTitle title={item.description} />
      <CardSeparator />
      <CardInfo label="Montant TTC" value={totalPriceLabel} flags={[CardInfoFlag.IMPORTANT, CardInfoFlag.QUANTITY]} />
    </CardRow>
  );
};

const OrdersSubRowReturn: React.FC<IOrdersSubRow> = ({item, attachedLines}) => {
  const unitLabel = `\u00A0${item.quantityUnit}`;
  const totalQuantityLabel = formatNumber(item.quantity as number)
    .negate()
    .round()
    .spaces()
    .renderWithUnit(item.quantityUnit);
  const amountIncludingVATLabel = formatNumber(item.amountIncludingVAT as number)
    .negate()
    .fixed()
    .spaces()
    .renderWithUnit('€');
  const unitPriceLabel = `${formatNumber(item.netUnitPrice as number)
    .fixed()
    .spaces()
    .renderWithUnit('€')}\u00A0€\u00A0/${unitLabel}`;

  return (
    <CardRow type={CardRowType.SUB}>
      <div className="flex max-lg:flex-col lg:items-center lg:justify-between gap-1">
        <CardSubTitle title={item.description} subTitle={item.familyName} />
        <OrdersStatusTag status={OrdersStatusEnum.RETURNED} />
      </div>
      <CardSeparator />
      <CardInfosWrapper>
        <CardInfoGroup>
          <CardInfo
            label="Quantité"
            value={totalQuantityLabel}
            flags={[CardInfoFlag.IMPORTANT, CardInfoFlag.QUANTITY, CardInfoFlag.RETURN]}
          />
          <CardInfo
            label="Prix unitaire HT"
            value={unitPriceLabel}
            flags={[CardInfoFlag.QUANTITY, CardInfoFlag.RETURN]}
          />
        </CardInfoGroup>
        <CardSeparator className="lg:hidden" />
        <CardInfoGroup>
          <CardInfo
            label="Montant TTC"
            value={amountIncludingVATLabel}
            flags={[CardInfoFlag.IMPORTANT, CardInfoFlag.QUANTITY, CardInfoFlag.RETURN]}
          />
          {attachedLines.map((line, key) => (
            <CardInfo
              key={key}
              label={line.description}
              value={formatNumber(line.amountIncludingVAT as number)
                .negate()
                .round()
                .fixed()
                .renderWithUnit('€')}
              flags={[CardInfoFlag.QUANTITY, CardInfoFlag.RETURN]}
            />
          ))}
        </CardInfoGroup>
        <CardSeparator className="lg:hidden" />
        <CardInfo
          label="Date de retour"
          value={formatDate(item.returnDate)}
          flags={[CardInfoFlag.IMPORTANT, CardInfoFlag.QUANTITY, CardInfoFlag.END_OF_LINE, CardInfoFlag.RETURN]}
        />
      </CardInfosWrapper>
    </CardRow>
  );
};

const OrdersSubRowReturnSimple: React.FC<IOrdersSubRow> = ({item}) => {
  const amountIncludingVATLabel = formatNumber(item.amountIncludingVAT as number)
    .negate()
    .fixed()
    .spaces()
    .renderWithUnit('€');
  return (
    <CardRow type={CardRowType.SUB}>
      <div className="flex max-lg:flex-col lg:items-center lg:justify-between gap-1">
        <CardSubTitle title={item.description} />
        <OrdersStatusTag status={OrdersStatusEnum.RETURNED} />
      </div>
      <CardSeparator />
      <CardInfo
        label="Montant TTC"
        value={amountIncludingVATLabel}
        flags={[CardInfoFlag.IMPORTANT, CardInfoFlag.QUANTITY, CardInfoFlag.RETURN]}
      />
    </CardRow>
  );
};

export const OrdersRowByOrder: React.FC = ({data}: any) => {
  const {isPending: isPdfDownloadPending, download: downloadPdf} = useDownloadPdf();
  const [isOpen, setIsOpen] = useState(false);
  const showMoreIcon = <Arrow direction={isOpen ? 'top' : 'bottom'} color="#0F4C36" />;

  const totalAmountIncludingVAT = data.lines.reduce((acc, item) => acc + item.amountIncludingVAT, 0);

  const {displayedLines, attachedLinesByProductLine} = useMemo(() => {
    return getTransformedDisplayedLines(data.lines);
  }, [data.lines]);

  const {displayedLines: returnsDisplayedLines, attachedLinesByProductLine: returnsAttachedLinesByProductLine} =
    useMemo(() => {
      return getTransformedDisplayedLines(data.returns);
    }, [data.lines]);

  return (
    <Card>
      <CardRow>
        <CardTitleContainer>
          <CardTitle
            title={`COMMANDE ${data.id}`}
            subTitle={formatDate(data.orderDate)}
            onDownload={() => downloadPdf(data.id, 5, data.id)}
          />
          <OrdersRowsCardTags invoiceStatut={data.invoiceStatut} shipmentStatut={data.shipmentStatut} />
        </CardTitleContainer>
      </CardRow>
      <CardRow>
        <CardInfosWrapper>
          <CardInfo label="Prévision de livraison" value={formatDate(data.requestedDeliveryDate)} />
          {data.linkedContracts.length > 0 && <CardInfo label="Contrat lié" value={data.linkedContracts.join(', ')} />}
          <CardSeparator />
          <CardInfo
            label="Montant TTC"
            value={`${formatNumber(totalAmountIncludingVAT as number)
              .fixed()
              .spaces()
              .render()}\u00A0€`}
            flags={[CardInfoFlag.QUANTITY]}
          />
          <CardInfo
            label="Pourcentage livré"
            value={<Progressbar percent={data.deliveredPercentage} theme={ProgressbarTheme.SOFT} />}
            flags={[CardInfoFlag.PROGRESSBAR, CardInfoFlag.END_OF_LINE]}
          />
        </CardInfosWrapper>
      </CardRow>
      {displayedLines.map((item, key) => {
        const isProduct = 'deliveredPercentage' in item;
        const attachedLines = attachedLinesByProductLine.get(item.line) ?? [];
        const Comp = isProduct
          ? isOpen
            ? OrdersSubRow
            : OrdersSubRowPreview
          : isOpen
          ? OrdersSubRowSimple
          : OrdersSubRowPreviewSimple;
        return <Comp key={key} order={data} item={item} attachedLines={attachedLines} />;
      })}
      {returnsDisplayedLines.map((item, key) => {
        const isProduct = !('attachedToLine' in item);
        const attachedLines = returnsAttachedLinesByProductLine.get(item.line) ?? [];
        const Comp = isProduct
          ? isOpen
            ? OrdersSubRowReturn
            : OrdersSubRowPreviewReturn
          : isOpen
          ? OrdersSubRowReturnSimple
          : OrdersSubRowPreviewReturnSimple;
        return <Comp key={key} order={data} item={item} attachedLines={attachedLines} />;
      })}
      <CardShowMore
        toggleIcon={showMoreIcon}
        onClick={() => {
          setIsOpen(!isOpen);
        }}
      >
        {getShowMoreProductLabel(isOpen, displayedLines.length + returnsDisplayedLines.length)}
      </CardShowMore>
    </Card>
  );
};
