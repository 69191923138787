import {flow, Instance, types} from 'mobx-state-tree';
import {SSOModel} from './models';
import {isDef} from '@lepicard/utils';
import {SSOConfig} from '@services/sso';

export type ISSOStore = Instance<typeof SSOModel>;

export const SSOStore = types.optional(
  SSOModel.views((self) => {
    return {
      get selectedExtranetUser() {
        if (!isDef(self.user) || !isDef(self.user.extranetUsers)) {
          return null;
        }

        return self.user.extranetUsers.find((value: any) => {
          return value.id === self.user!.selectedExtranetUserId;
        });
      },
      get salesPeople() {
        return (
          this.selectedExtranetUser?.contact?.contactSalesPeople?.filter(
            (contact) => contact.salesPersonPurchaser && ['PA', 'PV'].includes(contact.prodActivityCode as string)
          ) ?? []
        );
      },
    };
  })
    .actions((self) => {
      return {
        setMustRefreshUserInformation(value: boolean) {
          self.mustRefreshUserInformation = value;
        },
        setUser(user: ISSOStore['user']) {
          self.user = user;
        },
      };
    })
    // Actions est déclaré une 2ème fois pour que la fonction "setMustRefreshUserInformation" soit bien reconnu dans "self"
    .actions((self) => {
      return {
        retrieveAdditionalSSOInformation: flow(function* (token: string) {
          const serviceState = self.additionalInformationsState;
          serviceState.pending();

          try {
            const result = yield SSOConfig.fetchSSOInformation(token);
            self.user = result.data;

            // If the user waits 10m on the same page
            setTimeout(
              () => {
                self.setMustRefreshUserInformation(true);
                console.log('Expired : reloading user data');
              },
              SSOConfig.userCheckInterval * 60 * 1000
            );

            serviceState.done();
          } catch (error) {
            console.error('Cannot call user information', error);
            serviceState.fail({sendToast: false, error});
          }
        }),
      };
    }),
  {
    mustRefreshUserInformation: true,
  }
);
