import Select from '@components/NewFields/Select';
import FiltersCard from '@components/UI/FiltersCard/FiltersCard';
import React, {createContext, useContext, useEffect, useState} from 'react';
import AdvilorRow from '../AdvilorRow/advilorRow';
import {observer} from 'mobx-react-lite';
import {useStore} from '@stores/index';
import {Formik, useFormikContext} from 'formik';
import Pagination from '@components/Pagination';
import useEffectNotOnFirstRender from '@hooks/useEffectNotOnFirstRender';
import usePagination from '@hooks/usePagination';
import DatePicker from '@components/NewFields/DatePicker';
import {IAdivalorFilters} from '@services/navagri';
import NoData from '@components/NoData/NoData';
import {getNoDataMessage} from '@utils/tables';
import Loader from '@components/Loader';

interface IAdivalorListFormContext {
  formValues: any;
  refreshFormValues: (newValues: any) => void;
  locations: any[];
  types: any[];
}

export const AdivalorListFormContext = createContext<IAdivalorListFormContext>({
  formValues: {},
  refreshFormValues: () => {},
  locations: [],
  types: [],
});

const AdivalorListFilters: React.FC = () => {
  const {adivalor: adivalorStore} = useStore();
  const {refreshFormValues} = useContext(AdivalorListFormContext);
  const {values, setFieldValue} = useFormikContext();
  const locationOptions = adivalorStore.locations.map((location) => ({
    value: location,
  }));

  const typeOptions = adivalorStore.types.map((type) => ({
    value: type,
  }));

  useEffectNotOnFirstRender(() => {
    refreshFormValues(values);
  }, [values]);
  return (
    <div className="w-full mb-5">
      <FiltersCard className="filterCard">
        <div className="filteredValue">
          <div className="filteredRow">
            <label htmlFor="date">Date</label>
          </div>
          <DatePicker name="date" placeholder="JJ/MM/AA" />
        </div>
        <div className="filteredValue">
          <div className="filteredRow">
            <label htmlFor="locations">Lieux</label>
          </div>
          <Select
            name="locations"
            options={[{label: 'Touts les lieux', value: 'all'}, ...locationOptions]}
            placeholder="Touts les lieux"
          />
        </div>
        <div className="filteredValue lg:mr-0">
          <div className="filteredRow lg:min-w-[150px] min-w-[110px]">
            <label htmlFor="types">Type de déchets</label>
          </div>
          <Select name="types" options={[{label: 'Tous', value: 'all'}, ...typeOptions]} placeholder="Tous" />
        </div>
      </FiltersCard>
    </div>
  );
};

const AdivalorList: React.FC = observer(() => {
  const {adivalor: adivalorStore} = useStore();
  const loadData = (page: number) => adivalorStore.fetchPlanning(page, {...filters});
  const tableDatas = adivalorStore.planning ? adivalorStore.planning.toJSON() : [];
  const [filters, setFilters] = useState<IAdivalorFilters>({});
  const areDatasLoading = adivalorStore.fetchPlanningState.isPending;
  const initialFormValues = {
    date: '',
    locations: 'all',
    types: 'all',
  };

  useEffect(() => {
    onPageChange();
  }, [filters]);

  const [formValues, setFormValues] = useState(initialFormValues);
  const {pageNumber, onPageChange} = usePagination(loadData);

  const onFiltersChange = (values: any) => {
    const newFilters: IAdivalorFilters = {};
    Object.keys(values).forEach((key) => {
      const currentValue = values[key];

      if (currentValue !== '' && currentValue !== 'all') {
        newFilters[key as keyof IAdivalorFilters] = currentValue;
      }
    });

    setFilters(newFilters);
  };

  useEffect(() => {
    loadData(1);
  }, [filters]);

  useEffect(() => {
    // TODO: Reunit in a single fn
    onFiltersChange(formValues);
  }, [formValues]);

  return (
    <AdivalorListFormContext.Provider
      value={{
        formValues: formValues,
        refreshFormValues: (newValues) => setFormValues(newValues),
        locations: [],
        types: [],
      }}
    >
      <div>
        <Formik
          initialValues={formValues}
          onSubmit={() => {
            console.log(formValues);
          }}
        >
          <AdivalorListFilters />
        </Formik>

        {areDatasLoading ? (
          <Loader />
        ) : (
          <div>
            {tableDatas && tableDatas.length > 0 ? (
              tableDatas.map((result, idx) => <AdvilorRow key={idx} data={result} />)
            ) : (
              <NoData message={getNoDataMessage()} />
            )}
          </div>
        )}
        <Pagination
          activePage={pageNumber}
          itemsCountPerPage={10}
          totalItemsCount={adivalorStore.total ?? 0}
          onChange={onPageChange}
        />
      </div>
    </AdivalorListFormContext.Provider>
  );
});
export default AdivalorList;
