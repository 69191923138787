import { fetchCampaigns } from '@services/navagri'
import { ICampaignsStoreModel } from './models'
import { flow } from 'mobx-state-tree'

export default (self: ICampaignsStoreModel) => {
  return {
    fetchCampaigns: flow(function* () {
      const serviceState = self.fetchCampaignsState
      serviceState.pending()

      try {
        const { data } = yield fetchCampaigns()

        self.list = data

        serviceState.done()
      } catch (error) {

        serviceState.fail({error})
      }
    })
  }
}
