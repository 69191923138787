import { ApproDeliveriesModel } from './models'
import { types, flow, cast } from 'mobx-state-tree'
import { fetchSalesShipmentLines, IDeliveriesFilter } from "@services/navagri"
import { getPaginationParams, getGroupedByPaginationParams } from '@utils/tables'
import views from './views'
import {ConcurrentHelper} from "@utils/ConcurrentHelper";


export const ApproDeliveriesStore = types.optional(
    ApproDeliveriesModel.views(views).actions(self => {
        return {
            fetchDeliveries: flow(function* (page: number, filters?: IDeliveriesFilter) {
                const serviceState = self.fetchDeliveriesState
                serviceState.pending()

                try {


                    const {isLastAction} = ConcurrentHelper.setAction('fetchDeliveries');
                    const { data, total, limit, descriptions, manufacturerCode } = yield fetchSalesShipmentLines({ ...getPaginationParams(page), ...filters })
                    if (!isLastAction()) {
                      return;
                    }
                    self.list = data

                    self.descriptionsList = descriptions;

                    self.manufacturerCodeList = manufacturerCode;

                    serviceState.setPagination(total, limit)
                    serviceState.done()
                } catch (error) {
                    console.log("desc", error)

                    serviceState.fail({error})
                }
            }),

            fetchDeliveriesByGroup: flow(function* (page: number, filters?: IDeliveriesFilter) {
                const serviceState = self.fetchDeliveriesGrouByState
                serviceState.pending()
                try {
                    const {isLastAction} = ConcurrentHelper.setAction('fetchDeliveriesByGroup');
                    const { data, total, limit } = yield fetchSalesShipmentLines({ ...getGroupedByPaginationParams(page), ...filters })
                    if (!isLastAction()) {
                      return;
                    }
                    self.groupList = data
                    serviceState.setPagination(total, limit)
                    serviceState.done()
                } catch (error) {
                    serviceState.fail({error})
                }
            }),
          fetchCustomerCampaigns: flow(function* () {
            const serviceState = self.fetchCampaignsState
            serviceState.pending()
            try {
              const {isLastAction} = ConcurrentHelper.setAction('fetchCustomerCampaigns');
              const { campaigns } = yield fetchSalesShipmentLines({  })
              if (!isLastAction()) {
                return;
              }

              self.campaigns = campaigns
              serviceState.done()
            } catch (error) {
              serviceState.fail({error})
            }
          })
        }
    }),
    {}
)
